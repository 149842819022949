import React, { useEffect } from "react";
import { experienceData } from "../../Data/Data";
import gsap from "gsap";

function Experience() {
  useEffect(() => {
    function format_number(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    var counter = document.getElementById("counter");
    var value = {
      val: parseInt(counter.innerText),
    };
    gsap.from(value, {
      duration: 5,
      ease: "circ.out",
      val: 0,
      roundProps: "val",
      onUpdate: function () {
        counter.innerText = format_number(value.val);
      },
    });

    function format_number2(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    var counter2 = document.getElementById("counter2");
    var value2 = {
      val: parseInt(counter2.innerText),
    };
    gsap.from(value2, {
      duration: 5,
      ease: "circ.out",
      val: 0,
      roundProps: "val",
      onUpdate: function () {
        counter2.innerText = format_number2(value2.val);
      },
    });

    function format_number3(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    var counter3 = document.getElementById("counter3");
    var value3 = {
      val: parseInt(counter3.innerText),
    };
    gsap.from(value3, {
      duration: 5,
      ease: "circ.out",
      val: 0,
      roundProps: "val",
      onUpdate: function () {
        counter3.innerText = format_number3(value3.val);
      },
    });

    function format_number4(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    var counter4 = document.getElementById("counter4");
    var value4 = {
      val: parseInt(counter4.innerText),
    };
    gsap.from(value4, {
      duration: 5,
      ease: "circ.out",
      val: 0,
      roundProps: "val",
      onUpdate: function () {
        counter4.innerText = format_number4(value4.val);
      },
    });
  }, []);
  return (
    <section className="experience-section section-padding-normal">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 experience-height">
            <div className="row">
              <div
                className="col-6"
                style={{ paddingRight: "8px", paddingLeft: "8px" }}
              >
                <div className="experience-box ">
                  <img
                    className="icon-logo"
                    src={process.env.PUBLIC_URL + "/icons/icon-logo.svg"}
                  />
                  <h3 className="experience-number" id="counter">
                    06
                  </h3>
                  <p className="experience-term">Years of experience</p>
                </div>
              </div>
              <div
                className="col-6"
                style={{ paddingRight: "8px", paddingLeft: "8px" }}
              >
                <div className="experience-box">
                  <img
                    className="icon-logo"
                    src={process.env.PUBLIC_URL + "/icons/icon-logo.svg"}
                  />
                  <h3 className="experience-number" id="counter2">
                    970
                  </h3>
                  <p className="experience-term">Projects Done</p>
                </div>
              </div>
              <div
                className="col-6"
                style={{ paddingRight: "8px", paddingLeft: "8px" }}
              >
                <div className="experience-box">
                  <img
                    className="icon-logo"
                    src={process.env.PUBLIC_URL + "/icons/icon-logo.svg"}
                  />
                  <h3 className="experience-number" id="counter3">
                    450
                  </h3>
                  <p className="experience-term">Different Clients</p>
                </div>
              </div>
              <div
                className="col-6"
                style={{ paddingRight: "8px", paddingLeft: "8px" }}
              >
                <div className="experience-box">
                  <img
                    className="icon-logo"
                    src={process.env.PUBLIC_URL + "/icons/icon-logo.svg"}
                  />
                  <h3 className="experience-number" id="counter4">
                    1545
                  </h3>
                  <p className="experience-term">Cups of Coffee</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <img
              className="experience-img"
              src="https://cdn.dastkaar.studio/website-images/images/experience-img.webp"
            />
          </div>
        </div>
      </div>
    </section>
  );
}

export default Experience;
