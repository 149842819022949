import React, { useEffect, useState } from "react";
import gsap from "gsap";
import Navbar from "../../Nav/Navbar";
import Process from "../InteriorDesign/Process";
import Sidedrawer from "../../Nav/Sidedrawer";
import Loader from "../../Common/Loader";
import Footer from "../../Common/Footer/Footer";
import ScrollTrigger from "gsap/ScrollTrigger";
import ChangeImages from "./ChangeImages";
import BimBanner from "./BimBanner";
import BimText from "./BimText";

function BIMmain({ onToggle, handler, show, backdrop }) {
  const [display, setDisplay] = useState(false);
  useEffect(() => {
    document.title = "BIM | Dastkaar";
  }, []);
  useEffect(() => {
    setInterval(() => {
      setDisplay(true);
    }, 1500);
  }, []);

  gsap.registerPlugin(ScrollTrigger);
  useEffect(() => {
    gsap.utils.toArray(".comparisonSection").forEach((section) => {
      let bimTrigger = gsap.timeline({
        scrollTrigger: {
          trigger: section,
          start: "center center",
          end: () => "+=" + section.offsetWidth,
          scrub: true,
          pin: true,
          anticipatePin: 1,
        },
        defaults: { ease: "none" },
      });
      bimTrigger
        .fromTo(
          section.querySelector(".afterImage"),
          { yPercent: 100, y: 0 },
          { yPercent: 0 }
        )
        .fromTo(
          section.querySelector(".afterImage img"),
          { yPercent: -100, y: 0 },
          { yPercent: 0 },
          0
        );
      let bimToThis = gsap.timeline({
        defaults: { ease: "Power3.easeInOut", duration: 1 },
        scrollTrigger: {
          trigger: ".to-this-text",
          toggleActions: "play resume restart reverse",
          scrub: 1,
          start: "-240px bottom",
          pinSpacing: false,
        },
      });
      bimToThis.to(".to-this-text", {
        opacity: 1,
        y: 0,
        duration: 1.2,
      });
      let bimFromThis = gsap.timeline({
        defaults: { ease: "Power3.easeInOut", duration: 1 },
        scrollTrigger: {
          trigger: ".from-this-text",
          toggleActions: "play resume restart reverse",
          scrub: 1,
          start: "-240px bottom",
          pinSpacing: false,
        },
      });
      bimFromThis.to(".from-this-text", {
        opacity: 1,
        y: 0,
        duration: 1.2,
      });
      ScrollTrigger.refresh();
    });
  });

  return (
    <div data-lenis-prevent>
      {display === false ? (
        <Loader />
      ) : (
        <>
          <Navbar onToggle={onToggle} handler={handler} />
          <Sidedrawer onToggle={onToggle} show={show} handler={handler} />
          {backdrop}
          <main>
            <BimBanner />
            <ChangeImages />
            <BimText />
          </main>
          <Footer />
        </>
      )}
    </div>
  );
}

export default BIMmain;
