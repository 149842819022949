import React, { useEffect, useLayoutEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import Home from "./Pages/Home/Home";
// import Lenis from "@studio-freight/lenis";
import ProjectPageMain from "./Pages/ProjectPage/ProjectPageMain";
import ProjectDetail from "./Pages/ProjectDetail/ProjectDetail";
import {
  aboutUs,
  bim,
  blogPage,
  blogspage,
  commercialEstimates,
  concreteEstimates,
  contactUs,
  drywallEstimates,
  electricalEstimates,
  estimationPage,
  exteriorDesign,
  flooringEstimates,
  interiorDesign,
  lumberEstimates,
  masonryEstimates,
  paintingEstimates,
  projectDetailPage,
  projectsPage,
  resdentialEstimates,
  steelEstimates,
  walkthroughs,
} from "./utils/URLs";
import Navbar from "./Nav/Navbar";
// import Testing2 from "./Testing/Testing2";
import InteriorDesign from "./Pages/InteriorDesign/InteriorDesign";
import ExteriorDesign from "./Pages/ExteriorDesign/ExteriorDesign";
import ThreeD from "./Pages/ThreeD/ThreeD";
import { GlobalStyles } from "./DarkMode/Global";
import {
  darkTheme,
  darkThemeFooter,
  lightTheme,
  lightThemeFooter,
} from "./DarkMode/Theme";
import { useDarkMode } from "./DarkMode/DarkMode";
import { useState } from "react";
import Backdrop from "./Nav/Backdrop";
import Loader from "./Common/Loader";
import Main from "./Pages/About/AboutMain";
import About from "./Pages/About/AboutMain";
import ContactStart from "./Pages/Contact/ContactStart";
import ScrollToTop from "./Common/ScrollToTop";
import BIMmain from "./Pages/BIM/BIMmain";
import NoInternet from "./Common/NoInternet";
import ResidentialEstimates from "./Pages/ResidentialEstimates/ResidentialEstimates";
import ConcreteEstimates from "./Pages/ConcreteEstimates/ConcreteEstimates";
import SteelEstimates from "./Pages/SteelEstimates/SteelEstimates";
import MasonryEstimates from "./Pages/MasonryEstimates/MasonryEstimates";
import LumberEstimates from "./Pages/LumberEstimates/LumberEstimates";
import FlooringEstimates from "./Pages/FlooringEstimates/FlooringEstimates";
import ElectricalEstimates from "./Pages/ElectricalEstimates/ElectricalEstimates";
import DrywallEstimates from "./Pages/DrywallEstimates/DrywallEstimates";
import CommercialEstimates from "./Pages/CommercialEstimates/CommercialEstimates";
import PaintEstimates from "./Pages/PaintEstimates/PaintEstimates";
import Estimation from "./Pages/Estimation/Estimation";
import Blogs from "./Pages/Blogs/Blogs";
import BlogDetail from "./Pages/Blogs/blogDetail";

function App() {
  const btoa = window.btoa(
    `${process.env.REACT_APP_PERSONAL_USERNAME}:${process.env.REACT_APP_PERSONAL_PASSWORD}`
  );

  // const lenis = new Lenis({
  //   lerp: 0.05,
  //   smooth: true,
  //   direction: "vertical",
  // });

  // useLayoutEffect(() => {
  //   window.scrollTo(0, 0);

  //   window.lenis = lenis;
  //   setLenis(lenis);

  //   return () => {
  //     lenis.destroy();
  //     setLenis(null);
  //   };
  // }, []);

  // function raf() {
  //   lenis.raf();
  //   requestAnimationFrame(raf);
  // }

  // useEffect(() => {
  //   requestAnimationFrame(raf);
  // }, [window.location.pathname]);

  const [theme, toggleTheme] = useDarkMode("light");
  const [sideDrawerOpen, setSideDrawerOpen] = useState(false);
  function handler() {
    setSideDrawerOpen(!sideDrawerOpen);
  }
  function backdropHandler() {
    setSideDrawerOpen(!sideDrawerOpen);
  }
  let backdrop;

  if (sideDrawerOpen) {
    backdrop = <Backdrop click={backdropHandler} handler={handler} />;
  }

  return (
    <ThemeProvider theme={theme === "light" ? lightTheme : darkTheme}>
      <GlobalStyles />
      <React.Fragment>
        <NoInternet>
          <Routes>
            <Route
              path="/"
              element={
                <Home
                  btoa={btoa}
                  onToggle={toggleTheme}
                  handler={handler}
                  show={sideDrawerOpen}
                  backdrop={backdrop}
                />
              }
            />
            <Route
              path={`/${projectsPage}/:id`}
              element={
                <ProjectPageMain
                  btoa={btoa}
                  onToggle={toggleTheme}
                  handler={handler}
                  show={sideDrawerOpen}
                  backdrop={backdrop}
                />
              }
            />
            <Route
              path={`/${projectDetailPage}/:id`}
              element={
                <ProjectDetail
                  btoa={btoa}
                  onToggle={toggleTheme}
                  handler={handler}
                  show={sideDrawerOpen}
                  backdrop={backdrop}
                />
              }
            />
            <Route
              path={`/${blogspage}`}
              element={
                <Blogs
                  btoa={btoa}
                  onToggle={toggleTheme}
                  handler={handler}
                  show={sideDrawerOpen}
                  backdrop={backdrop}
                />
              }
            />
            <Route
              path={`/${blogPage}/:id`}
              element={
                <BlogDetail
                  btoa={btoa}
                  onToggle={toggleTheme}
                  handler={handler}
                  show={sideDrawerOpen}
                  backdrop={backdrop}
                />
              }
            />
            <Route
              path={`/${estimationPage}`}
              element={
                <Estimation
                  onToggle={toggleTheme}
                  handler={handler}
                  show={sideDrawerOpen}
                  backdrop={backdrop}
                />
              }
            />
            <Route
              path={`/${interiorDesign}`}
              element={
                <InteriorDesign
                  onToggle={toggleTheme}
                  handler={handler}
                  show={sideDrawerOpen}
                  backdrop={backdrop}
                />
              }
            />
            <Route
              path={`/${exteriorDesign}`}
              element={
                <ExteriorDesign
                  onToggle={toggleTheme}
                  handler={handler}
                  show={sideDrawerOpen}
                  backdrop={backdrop}
                />
              }
            />
            <Route
              path={`/${walkthroughs}`}
              element={
                <ThreeD
                  onToggle={toggleTheme}
                  handler={handler}
                  show={sideDrawerOpen}
                  backdrop={backdrop}
                />
              }
            />
            <Route
              path={`/${resdentialEstimates}`}
              element={
                <ResidentialEstimates
                  onToggle={toggleTheme}
                  handler={handler}
                  show={sideDrawerOpen}
                  backdrop={backdrop}
                />
              }
            />
            <Route
              path={`/${commercialEstimates}`}
              element={
                <CommercialEstimates
                  onToggle={toggleTheme}
                  handler={handler}
                  show={sideDrawerOpen}
                  backdrop={backdrop}
                />
              }
            />
            <Route
              path={`/${concreteEstimates}`}
              element={
                <ConcreteEstimates
                  onToggle={toggleTheme}
                  handler={handler}
                  show={sideDrawerOpen}
                  backdrop={backdrop}
                />
              }
            />
            <Route
              path={`/${steelEstimates}`}
              element={
                <SteelEstimates
                  onToggle={toggleTheme}
                  handler={handler}
                  show={sideDrawerOpen}
                  backdrop={backdrop}
                />
              }
            />
            <Route
              path={`/${paintingEstimates}`}
              element={
                <PaintEstimates
                  onToggle={toggleTheme}
                  handler={handler}
                  show={sideDrawerOpen}
                  backdrop={backdrop}
                />
              }
            />
            <Route
              path={`/${masonryEstimates}`}
              element={
                <MasonryEstimates
                  onToggle={toggleTheme}
                  handler={handler}
                  show={sideDrawerOpen}
                  backdrop={backdrop}
                />
              }
            />
            <Route
              path={`/${lumberEstimates}`}
              element={
                <LumberEstimates
                  onToggle={toggleTheme}
                  handler={handler}
                  show={sideDrawerOpen}
                  backdrop={backdrop}
                />
              }
            />
            <Route
              path={`/${flooringEstimates}`}
              element={
                <FlooringEstimates
                  onToggle={toggleTheme}
                  handler={handler}
                  show={sideDrawerOpen}
                  backdrop={backdrop}
                />
              }
            />
            <Route
              path={`/${electricalEstimates}`}
              element={
                <ElectricalEstimates
                  onToggle={toggleTheme}
                  handler={handler}
                  show={sideDrawerOpen}
                  backdrop={backdrop}
                />
              }
            />
            <Route
              path={`/${drywallEstimates}`}
              element={
                <DrywallEstimates
                  onToggle={toggleTheme}
                  handler={handler}
                  show={sideDrawerOpen}
                  backdrop={backdrop}
                />
              }
            />
            <Route
              path={`/${masonryEstimates}`}
              element={
                <MasonryEstimates
                  onToggle={toggleTheme}
                  handler={handler}
                  show={sideDrawerOpen}
                  backdrop={backdrop}
                />
              }
            />
            <Route
              path={`/${aboutUs}`}
              element={
                <About
                  btoa={btoa}
                  onToggle={toggleTheme}
                  handler={handler}
                  show={sideDrawerOpen}
                  backdrop={backdrop}
                />
              }
            />
            <Route
              path={`/${contactUs}`}
              element={
                <ContactStart
                  onToggle={toggleTheme}
                  handler={handler}
                  show={sideDrawerOpen}
                  backdrop={backdrop}
                />
              }
            />
            <Route
              path={`/${bim}`}
              element={
                <BIMmain
                  onToggle={toggleTheme}
                  handler={handler}
                  show={sideDrawerOpen}
                  backdrop={backdrop}
                />
              }
            />
          </Routes>
        </NoInternet>
      </React.Fragment>
    </ThemeProvider>
  );
}

export default App;
