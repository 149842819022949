import React, { useEffect, useState } from "react";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import Process from "../InteriorDesign/Process";
import Banner from "./Banner";
import { threeD_Design } from "../../Data/Data";
import Navbar from "../../Nav/Navbar";
import Sidedrawer from "../../Nav/Sidedrawer";
import Footer from "../../Common/Footer/Footer";
import Loader from "../../Common/Loader";

function ThreeD({ onToggle, handler, show, backdrop }) {
  const [display, setDisplay] = useState(false);
  useEffect(() => {
    document.title = "3D Walkthroughs | Dastkaar";
  }, []);
  useEffect(() => {
    setInterval(() => {
      setDisplay(true);
    }, 1500);
  }, []);
  return (
    <div data-lenis-prevent>
      {display === false ? (
        <Loader />
      ) : (
        <>
          <Navbar onToggle={onToggle} handler={handler} />
          <Sidedrawer onToggle={onToggle} show={show} handler={handler} />
          {backdrop}
          <main>
            <Banner />
            <Process props={threeD_Design} />
          </main>
          <Footer />
        </>
      )}
    </div>
  );
}

export default ThreeD;
