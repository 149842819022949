import React, { useEffect } from "react";
import gsap from "gsap";
import { Link } from "react-router-dom";

function HeroSection() {
  useEffect(() => {
    let tl = gsap.timeline({
      defaults: { ease: "Power3.easeInOut", duration: 1 },
    });
    tl.to(".hero-section-heading", {
      clipPath: "polygon(100% 0, 0 0, 0 100%, 100% 100%)",
      opacity: 1,
      x: 0,
      duration: 1.2,
    });
    let tl_para = gsap.timeline({
      defaults: { ease: "Power3.easeInOut", duration: 1 },
    });
    tl_para.to(".hero-section-description", {
      clipPath: "polygon(100% 0, 0 0, 0 100%, 100% 100%)",
      opacity: 1,
      x: 0,
      delay: 0.4,
      duration: 1.2,
    });
    let imgTl = gsap.timeline({
      defaults: { ease: "Circ.easeOut", duration: 1.6 },
    });
    imgTl.to(".hero-section-img", {
      opacity: 1,
      y: 0,
      duration: 1.8,
    });
    let imgTl2 = gsap.timeline({
      defaults: { ease: "Circ.easeOut", duration: 1.5 },
    });
    imgTl2.to(".hero-section-circle", {
      opacity: 1,
      y: 0,
      delay: 0.5,
      duration: 3,
    });
  });

  return (
    <section className="home-hero-section trigger-item">
      <div
        className="container-fluid"
        style={{ paddingRight: "0px", paddingLeft: "0px" }}
      >
        <div className="hero-section-center">
          <img
            className="hero-section-img"
            src="https://cdn.dastkaar.studio/website-images/backgrounds/hero-section-2.webp"
          />
          <img
            className="hero-section-circle"
            src="https://cdn.dastkaar.studio/website-images/backgrounds/circle.svg"
          />
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <h1 className="hero-section-heading">
              Architecture with people in mind
            </h1>
            <div className="hero-align">
              <div className="line"></div>
              <h1 className="hero-dot">.</h1>
            </div>
            {/* <h1
              className="hero-section-description"
              style={{ textAlign: "center", marginRight: "100px" }}
            >
              &
            </h1> */}
            <h1 className="hero-section-description">
              Estimation with winning bids in mind.
            </h1>
            <Link to="/contact">
              <div className="main-btn hero-section-btn">
                Let's Talk{" "}
                <img
                  className="main-btn-img"
                  src={process.env.PUBLIC_URL + "/icons/arrow.svg"}
                  alt="arrow"
                />
              </div>
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
}

export default HeroSection;
