import React, { useEffect, useState } from "react";
import gsap from "gsap";
import Footer from "../../Common/Footer/Footer";
import Navbar from "../../Nav/Navbar";
import Sidedrawer from "../../Nav/Sidedrawer";
import ContactForm from "./ContactForm";
import ScrollToTop from "../../Common/ScrollToTop";
import Loader from "../../Common/Loader";

function ContactStart({ onToggle, show, handler, backdrop }) {
  useEffect(() => {
    document.title = "Contact | Dastkaar";
  }, []);
  useEffect(() => {
    let tl = gsap.timeline({
      defaults: { ease: "Power3.easeInOut", duration: 1 },
    });
    tl.to(".contact-heading", {
      clipPath: "polygon(100% 0, 0 0, 0 100%, 100% 100%)",
      opacity: 1,
      x: 0,
      duration: 1.2,
    });
  });
  const [display, setDisplay] = useState(false);
  useEffect(() => {
    setInterval(() => {
      setDisplay(true);
    }, 1500);
  }, []);
  return (
    <div data-lenis-prevent>
      {display === false ? (
        <Loader />
      ) : (
        <>
          <Navbar onToggle={onToggle} handler={handler} />
          <Sidedrawer onToggle={onToggle} show={show} handler={handler} />
          {backdrop}
          <main>
            <section className="section-padding-top">
              <div className="container" style={{ paddingTop: "8vh" }}>
                <div className="row flex-center">
                  <div className="col-lg-10">
                    <h1 className="contact-heading">
                      {/* Here to solve all of your issues! */}
                      Get the help of a professional. Here to solve all your estimation and design needs
                    </h1>
                  </div>
                  {/* <div className="col-lg-7">
                    <img
                      className="w-100"
                      src="https://cdn.dastkaar.studio/website-images/images/contact-us.webp"
                      alt=""
                    />
                  </div> */}
                </div>
              </div>
            </section>
            <ContactForm />
          </main>
          <Footer />
        </>
      )}
    </div>
  );
}

export default ContactStart;
