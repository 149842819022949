import React, { useEffect } from "react";
import Navbar from "../../Nav/Navbar";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import Sidedrawer from "../../Nav/Sidedrawer";
import Hero from "./Hero";
import Footer from "../../Common/Footer/Footer";
import Projects from "../Home/Projects";
function Main({
  onToggle,
  show,
  handler,
  backdrop,
  projectsData,
  gsapLoading,
}) {
  gsap.registerPlugin(ScrollTrigger);

  useEffect(() => {
    //navbarTrigger
    // let projects_page_navbar_trigger = gsap.timeline({
    //   scrollTrigger: {
    //     trigger: ".project-trigger-item",
    //     start: "bottom center",
    //     end: "bottom top",
    //     scrub: 1,
    //   },
    // });
    // projects_page_navbar_trigger
    //   .to(".header", {
    //     padding: "20px 0px",
    //     backgroundColor: "transparent",
    //     duration: 1,
    //   })
    //   .to(".navbar-main", {
    //     border: "1px solid #c9981a",
    //   });
    //ProjectsHeading
    let projects_page = gsap.timeline({
      defaults: { ease: "Power3.easeInOut", duration: 1 },
    });
    projects_page.to(".projects-hero-heading", {
      clipPath: "polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)",
      // x: 0,
      // color: "#1C1B1B",
      duration: 2.2,
    });
    // ProjectsImage;
    let projects_page_Image = gsap.timeline({
      defaults: { ease: "Circ.easeOut" },
    });
    projects_page_Image.to(".svg-img", {
      opacity: 1,
      duration: 2.8,
    });
    // let residentialTrigger = gsap.timeline({
    //   defaults: { ease: "Power3.easeInOut", duration: 1 },
    //   scrollTrigger: {
    //     trigger: ".residential-projects-box",
    //     toggleActions: "play resume restart reverse",
    //     once: true,
    //     start: "top bottom",
    //     scrub: 1,
    //   },
    // });
    // residentialTrigger.to(".project-name-span", {
    //   opacity: 1,
    //   clipPath: "polygon(100% 0px, 0px 0px, 0px 100%, 100% 100%)",
    //   transform: "translate(0px, 0px)",
    //   duration: 1.2,
    //   stagger: 0.2,
    // });
    //2 => Residential Main Heading
    // let residentialTrigger_2 = gsap.timeline({
    //   defaults: { ease: "Power3.easeInOut", duration: 1 },
    //   scrollTrigger: {
    //     trigger: ".residential-projects",
    //     scrub: 1,
    //   },
    // });
    // residentialTrigger_2.to(".residential-heading", {
    //   transform: "translateX(800px)",
    // });
    //3 => Residential Projects Image
    // let residentialTrigger_3 = gsap.timeline({
    //   defaults: { ease: "Power3.easeInOut", duration: 1 },
    //   scrollTrigger: {
    //     trigger: ".residential-projects",
    //     scrub: 1,
    //     start: "0px bottom",
    //   },
    // });
    // residentialTrigger_3.to(".project-img", {
    //   transform: "scale(1)",
    // });
    //CommercialProjectsTrigger
    //1 =>
    // let commericalTrigger = gsap.timeline({
    //   defaults: { ease: "Power3.easeInOut", duration: 1 },
    //   scrollTrigger: {
    //     trigger: ".commerical-projects-box",
    //     toggleActions: "play resume restart reverse",
    //     once: true,
    //     scrub: 1,
    //     // start: "top bottom",
    //   },
    // });
    // commericalTrigger.to(".commercial-span", {
    //   opacity: 1,
    //   clipPath: "polygon(100% 0px, 0px 0px, 0px 100%, 100% 100%)",
    //   transform: "translate(0px, 0px)",
    //   duration: 1.2,
    //   stagger: 0.2,
    // });
    // let commericalTrigger_2 = gsap.timeline({
    //   defaults: { ease: "Power3.easeInOut", duration: 1 },
    //   scrollTrigger: {
    //     trigger: ".commerical-projects",
    //     scrub: 1,
    //   },
    // });
    // commericalTrigger_2.to(".commerical-heading", {
    //   transform: "translateX(800px)",
    // });
    // let commericalTrigger_4 = gsap.timeline({
    //   defaults: { ease: "Power3.easeInOut", duration: 1 },
    //   scrollTrigger: {
    //     trigger: ".commerical-projects",
    //     scrub: 1,
    //     start: "top center",
    //   },
    // });
    // commericalTrigger_4.to(".project-img-2", {
    //   transform: "scale(1)",
    // });
    // Refresh
    // ScrollTrigger.refresh();
  });
  return (
    <>
      <Navbar onToggle={onToggle} handler={handler} />
      <Sidedrawer onToggle={onToggle} show={show} handler={handler} />
      {backdrop}
      <main>
        <Hero />
        <Projects projectsData={projectsData} />
      </main>
      <Footer />
    </>
  );
}

export default Main;
