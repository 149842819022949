import DownloadPdfButton from "../../Common/DownloadPdfButton";
import EstimationCategories from "../../Common/EstimationCategories";
import EstimationContact from "../../Common/EstimationContact";
import ServicesList from "../../Common/ServicesList";
import { drywallData } from "../../Data/Data";

const Main = () => {
  return (
    <section className="section-padding-normal " data-lenis-prevent>
      <div className="container">
        <div className="row">
          <div className="col-lg-9">
            <h1 className="estimate-heading">
              DRYWALL TAKEOFF & ESTIMATING SERVICES
            </h1>
            <p className="minor-description-3">
              Are you in search of professional drywall estimating services for
              your construction projects? Are you seeking accurate drywall
              takeoff services to determine the necessary materials for your
              project? Your search ends here at Dastkaar Studio. Our team at
              Dastkaar Studio specializes in providing comprehensive{" "}
              <span className="span-color">drywall takeoff estimates</span>,
              encompassing materials, labor, and equipment needed to complete
              your project successfully. With over 6 years of experience in
              estimating drywall for both residential and commercial
              construction projects in the USA, our expert team ensures{" "}
              <span className="span-color">precise and reliable results</span>{" "}
              tailored to your specific needs.
            </p>
            <h1 className="estimate-heading mt-50">
              Hire Expert Drywall Estimators:
            </h1>
            <p className="minor-description-3">
              At Dastkaar Studio, we pride ourselves on being a premier
              estimating firm offering top-notch services to contractors,
              builders, homeowners, and architects across the United States. Our
              team comprises seasoned drywall estimators with extensive
              experience in construction takeoffs, costing, and budgeting.
            </p>
            <p className="minor-description-3">
              We boast specialized quantity surveyors proficient in various CSI
              construction trades, including but not limited to lumber
              estimating, steel estimating, concrete estimating, masonry
              estimating, painting estimates, flooring estimates, and electrical
              estimates.
            </p>
            <h1 className="estimate-heading mt-50">
              Why We Standout in Drywall Takeoff Services:
            </h1>
            <p className="minor-description-3">
              Adhering strictly to local construction codes, we ensure that our
              estimates meet the requirements set forth by local authorities.
              Furthermore, our dedicated estimators undergo rigorous training
              and adhere closely to the estimating guidelines outlined by
              reputable organizations such as ASPE, PMI, AWCI, and others.
            </p>
            <p className="minor-description-3">
              Choose Dastkaar Studio for accurate, reliable, and compliant
              drywall estimating services tailored to your project's needs.
            </p>
            <h1 className="estimate-heading  mt-50">
              What We Offer in Drywall Takeoff Services:
            </h1>
            <p className="minor-description-3">
              At Dastkaar Studio, our comprehensive{" "}
              <span className="span-color">drywall takeoff service</span>{" "}
              entails a detailed breakdown of various sheetrock materials and
              related accessories, including:
            </p>
            <div className="row">
              <ServicesList services={drywallData} />
            </div>
            <div className="row">
              <div className="col-lg-6">
                <img
                  className="estimate-img"
                  src={process.env.PUBLIC_URL + "/images/drywall.webp"}
                  alt="resdential"
                />
              </div>
              <div className="col-lg-6">
                <img
                  className="estimate-img"
                  src={process.env.PUBLIC_URL + "/images/drywall-pdf.webp"}
                  alt="resdential"
                />
              </div>
            </div>
            <div className="download-button">
              <DownloadPdfButton url="Drywall.pdf" />
            </div>
            <p className="minor-description-3">
              Trust <span className="span-color">Dastkaar Studio </span>
              to provide you with accurate and thorough estimates for all your
              drywall needs, ensuring your project is meticulously planned and
              executed to perfection.
            </p>
          </div>
          <div className="col-lg-3">
            <EstimationCategories />
            <EstimationContact />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Main;
