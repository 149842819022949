import React, { useEffect, useState } from "react";
import Loader from "../../Common/Loader";
import Main from "./Main";
function Home({ btoa, onToggle, show, handler, backdrop, lenis }) {
  //

  useEffect(() => {
    document.title = "Home | Dastkaar";
  }, []);
  const [loading, setLoading] = useState(false);
  const [projectsData, setProjectsData] = useState();

  const [teamLoader, setTeamLoader] = useState(false);
  const [teamData, setTeamData] = useState();

  const fetchProjectsData = async () => {
    try {
      const data = await fetch(`${process.env.REACT_APP_BASE_URL}/project/`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Basic " + btoa,
        },
      });
      const response = await data.json();
      const filtered = response.filter(response => {
        return response.priority == 10;
      })
      setProjectsData(filtered);
    } catch (error) {
      console.log(console.error);
    }
  };

  const fetchTeamData = async () => {
    try {
      const res = await fetch(`${process.env.REACT_APP_BASE_URL}/member/`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Basic " + btoa,
        },
      });
      const response = await res.json();
      setTeamData(response);
    } catch (error) {
      console.log(console.error);
    }
  };

  const [gsapLoading, setGsapLoading] = useState(false);

  useEffect(() => {
    fetchProjectsData();
    fetchTeamData();
  }, []);

  useEffect(() => {
    if (projectsData !== undefined && teamData !== undefined) {
      setLoading(false);
      setTeamLoader(false);
    } else {
      setLoading(true);
      setTeamLoader(true);
    }
  });

  useEffect(() => {
    if (loading === true && teamLoader === true) {
      setGsapLoading(true);
    }
  }, [loading, teamLoader]);

  return (
    <div data-lenis-prevent>
      {loading === true && teamLoader === true ? (
        <Loader />
      ) : (
        <>
          <Main
            onToggle={onToggle}
            show={show}
            handler={handler}
            backdrop={backdrop}
            projectsData={projectsData}
            teamData={teamData}
            gsapLoading={gsapLoading}
          />
        </>
      )}
    </div>
  );
}

export default Home;
