import DownloadPdfButton from "../../Common/DownloadPdfButton";
import EstimationCategories from "../../Common/EstimationCategories";
import EstimationContact from "../../Common/EstimationContact";
import ServicesList from "../../Common/ServicesList";
import { lumberData1, lumberData2, lumberData3 } from "../../Data/Data";

const Main = () => {
  return (
    <section className="section-padding-normal " data-lenis-prevent>
      <div className="container">
        <div className="row">
          <div className="col-lg-9">
            <h1 className="estimate-heading">
              LUMBER TAKEOFF & ESTIMATING SERVICES
            </h1>
            <p className="minor-description-3">
              At Dastkaar Studio, we specialize in providing lumber takeoffs for
              a wide range of residential and commercial projects, spanning from
              hotels and shopping malls to hospitals and apartment buildings.
              With extensive experience in estimating both multi-story
              wood-framed projects and lumber-steel composite framing projects,
              we ensure thorough and detailed takeoffs that encompass every
              aspect of lumber framing.
              <br />
              Our meticulous takeoffs are invaluable for material ordering and
              accurately estimating construction costs. Whether it's a new
              commercial construction project or a tenant improvement (TI)
              project, our estimates cover all essential aspects of lumber
              framing.
              <br />
              For new commercial projects, our scope of work includes:
            </p>
            <ServicesList services={lumberData1} single={true} />
            <p className="minor-description-3">
              For tenant improvement (TI) projects, our scope of work expands to
              include additional elements such as:
            </p>
            <ServicesList services={lumberData2} single={true} />
            <p className="minor-description-3">
              Trust Dastkaar Studio to deliver precise and comprehensive lumber
              takeoffs tailored to your project's specific needs, ensuring
              efficient material ordering and accurate cost estimation for
              successful project execution.
            </p>
            <h1 className="estimate-heading mt-50">
              Why We Standout in Lumber Takeoff Services:
            </h1>
            <p className="minor-description-3">
              At Dastkaar Studio, our lumber takeoff service is characterized by
              its{" "}
              <span className="span-color">thoroughness and meticulous</span>{" "}
              attention to detail, carried out by our expert{" "}
              <span className="span-color">framing estimators</span>. We
              understand the critical importance of incorporating as much
              information as possible from the provided plans and project
              specifications. A lumber takeoff lacking essential details, such
              as types and sizes, renders it ineffective for placing orders with
              lumber yards.
            </p>
            <p className="minor-description-3">
              An inaccurate lumber list can result in significant additional
              costs. Ordering materials based on erroneous information can lead
              to unnecessary expenses, including extra freight charges.
              Moreover, many lumber yards have strict no-return policies,
              exacerbating the consequences of inaccuracies.
              <br />
            </p>
            <p className="minor-description-3">
              Hence, it is paramount to enlist the services of professional
              framing estimators to ensure the preparation of an{" "}
              <span className="soan-color">accurate framing estimate</span> for
              your project. With Dastkaar Studio, you can trust that our team
              will deliver precise and reliable lumber takeoffs, minimizing
              potential errors and optimizing the efficiency of your
              construction process.
            </p>

            <h1 className="estimate-heading  mt-50">
              What We Offer in Lumber Takeoff Services:
            </h1>
            <p className="minor-description-3">
              At Dastkaar Studio, our lumber takeoff services encompass a
              comprehensive range of woodwork within Division 06 of the CSI
              Master Format. Our quantification includes:
            </p>
            <div className="row">
              <ServicesList services={lumberData3} />
            </div>
            <div className="row">
              <div className="col-lg-6">
                <img
                  className="estimate-img"
                  src={process.env.PUBLIC_URL + "/images/lumber.webp"}
                  alt="resdential"
                />
              </div>
              <div className="col-lg-6">
                <img
                  className="estimate-img"
                  src={process.env.PUBLIC_URL + "/images/lumber-pdf.webp"}
                  alt="resdential"
                />
              </div>
            </div>
            <div className="download-button">
              <DownloadPdfButton url="Lumber.pdf" />
            </div>
            <p className="minor-description-3">
              With our meticulous approach and attention to detail, Dastkaar
              Studio ensures accurate and comprehensive lumber takeoffs tailored
              to your project's specific requirements. Trust us to provide
              precise estimates to facilitate seamless material procurement and
              cost-effective project management.
            </p>
          </div>
          <div className="col-lg-3">
            <EstimationCategories />
            <EstimationContact />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Main;
