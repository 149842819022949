import React from "react";
import FileUpload from "../../Common/FileUpload";

function Form({
  setUserName,
  setUserEmail,
  userName,
  userEmail,
  setMessage,
  disableBtn,
  SubmitForm,
  setDisableBtn,
  setPdf,
}) {
  return (
    <form onSubmit={SubmitForm}>
      <div className="row">
        <div className="col-lg-6">
          <div className="contact-input">
            <label className="label-class">Name</label>
            <input
              type="text"
              className="input-box"
              placeholder="Enter Name"
              value={userName}
              disabled={disableBtn}
              onChange={(e) => setUserName(e.target.value)}
            />
          </div>
        </div>
        <div className="col-lg-6">
          <div className="contact-input">
            <label className="label-class">Email</label>
            <input
              type="email"
              className="input-box"
              placeholder="Enter Email"
              value={userEmail}
              disabled={disableBtn}
              onChange={(e) => setUserEmail(e.target.value)}
            />
          </div>
        </div>
        <div className="col-lg-12">
          <div className="contact-input">
            <label className="label-class">Your Message</label>
            <textarea
              type="text"
              className="input-box"
              placeholder="Your Message"
              disabled={disableBtn}
              onChange={(e) => setMessage(e.target.value)}
            />
          </div>
        </div>
        <div className="col-lg-12">
          <div className="contact-input">
            <label className="label-class">Select File</label>
            <input
              type="file"
              id="pdf-file-input-box"
              className="input-box"
              placeholder="Your Message"
              disabled={disableBtn}
              onChange={(e) => setPdf(e.currentTarget.files[0])}
            />
          </div>
        </div>
        <div className="col-lg-12">
          <div className="contact-input">
            <button type="submit" className="talk-btn" disabled={disableBtn}>
              Send{" "}
              <div className="talk-btn-img">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="10.684"
                  height="10.684"
                  viewBox="0 0 10.684 10.684"
                >
                  <g
                    id="Icon_feather-arrow-up-right"
                    data-name="Icon feather-arrow-up-right"
                    transform="translate(-9.086 -9.086)"
                  >
                    <path
                      id="Path_115"
                      data-name="Path 115"
                      d="M10.5,18.355,18.355,10.5"
                      fill="none"
                      className="talk-btn-svg"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                    />
                    <path
                      id="Path_116"
                      data-name="Path 116"
                      d="M10.5,10.5h7.855v7.855"
                      fill="none"
                      className="talk-btn-svg"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                    />
                  </g>
                </svg>
              </div>
            </button>
          </div>
        </div>
      </div>
    </form>
  );
}

export default Form;
