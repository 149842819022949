import React from "react";
import Lottie from "react-lottie";
// import animationData from "../Lottie/test.json";
import animationData from "../Lottie/logoAnimation.json";

// function Loader() {
//   return (
//     <div className="loader-main">
//       <div className="loading">
//         <div className="loading-dots"></div>
//         <div className="loading-dots"></div>
//         <div className="loading-dots"></div>
//         <div className="loading-dots"></div>
//         <div className="loading-dots"></div>
//         <div className="loading-dots"></div>
//         <div className="loading-dots"></div>
//         <div className="loading-dots"></div>
//         <div className="loading-dots"></div>
//         <div className="loading-dots"></div>
//         <div className="loading-dots"></div>
//         <div className="loading-dots"></div>
//         <div className="loading-dots"></div>
//         <div className="loading-dots"></div>
//         <div className="loading-dots"></div>
//         <div className="loading-dots"></div>
//       </div>
//     </div>
//   );
// }

function Loader() {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <div className="loader-main">
      <Lottie options={defaultOptions} height={216} width={216} />
    </div>
  );
}

export default Loader;
