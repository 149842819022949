import {
  commercialEstimates,
  concreteEstimates,
  drywallEstimates,
  electricalEstimates,
  flooringEstimates,
  lumberEstimates,
  masonryEstimates,
  paintingEstimates,
  resdentialEstimates,
  steelEstimates,
} from "../utils/URLs";

export const experienceData = [
  {
    id: 0,
    number: "04",
    text: "Years of experience",
  },
  {
    id: 1,
    number: "740",
    text: "Projects done",
  },
  {
    id: 2,
    number: "30",
    text: "Different Clients",
  },
  {
    id: 3,
    number: "921",
    text: "Cups of coffee",
  },
];

export const interiorDesign = [
  {
    id: 0,
    subHeading: "Client Provides",
    heading: "Drawings",
    description:
      "Send us your site plan and dimensions, and let our expert team take care of the rest. From creating detailed 2D or 3D plans to providing accurate dimensions and elevations, we've got you covered every step of the way.",
    images: {
      first: "https://cdn.dastkaar.studio/website-images/images/d-1-a.webp",
      second: "https://cdn.dastkaar.studio/website-images/images/d-1-b.webp",
      third: "https://cdn.dastkaar.studio/website-images/images/d-1-c.webp",
    },
    backgroundImage: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="346"
        height="440"
        viewBox="0 0 346 440"
        className="svg-mobile"
      >
        <text
          id="_01"
          data-name="01"
          transform="translate(173 348)"
          fill="none"
          stroke="#cb9817"
          strokeWidth="1"
          fontSize="365"
          fontFamily="AlbertSans-Bold, Albert Sans"
          fontWeight="700"
          letterSpacing="-0.04em"
        >
          <tspan x="-171.185" y="0">
            01
          </tspan>
        </text>
      </svg>
    ),
    triggerName: "interior-trigger",
    imagesTrigger: {
      first: "img-1-a",
      second: "img-1-b",
    },
    circleTrigger: "circle-sm-1",
  },
  {
    id: 1,
    subHeading: "SKETCHUP & REVIT",
    heading: "3D Modeling",
    description:
      "After completing the drawing phase, we seamlessly transition to the 3D modeling stage. With expertise in both SketchUp and Revit, we adapt to your preference, ensuring precise and hyper-detailed 3D models tailored to your specifications",
    images: {
      first: "https://cdn.dastkaar.studio/website-images/images/d-2-a.webp",
      second: "https://cdn.dastkaar.studio/website-images/images/d-2-b.webp",
      third: "https://cdn.dastkaar.studio/website-images/images/d-2-c.webp",
    },
    imagesTrigger: {
      first: "img-2-a",
      second: "img-2-b",
    },
    backgroundImage: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="440"
        height="440"
        viewBox="0 0 440 440"
        className="svg-mobile"
      >
        <text
          id="_02"
          data-name="02"
          transform="translate(220 348)"
          fill="none"
          stroke="#cb9817"
          strokeWidth="1"
          fontSize="365"
          fontFamily="AlbertSans-Bold, Albert Sans"
          fontWeight="700"
          letterSpacing="-0.04em"
        >
          <tspan x="-218.452" y="0">
            02
          </tspan>
        </text>
      </svg>
    ),
    triggerName: "interior-trigger-2",
    circleTrigger: "circle-sm-2",
  },
  {
    id: 2,
    subHeading: "Lumion",
    heading: "Rendering",
    description:
      "After finalizing the 3D model to your satisfaction, we enter the 3D visualization phase, leveraging advanced rendering software like Lumion and D5. Our goal is to provide hyper-realistic visualizations that vividly portray your project, aiding your imagination in envisioning the exact look and feel of the final result.",
    images: {
      first: "https://cdn.dastkaar.studio/website-images/images/d-3-a.webp",
      second: "https://cdn.dastkaar.studio/website-images/images/d-3-b.webp",
      third: "https://cdn.dastkaar.studio/website-images/images/d-3-c.webp",
    },
    imagesTrigger: {
      first: "img-3-a",
      second: "img-3-b",
    },
    backgroundImage: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="440"
        height="440"
        viewBox="0 0 440 440"
        className="svg-mobile"
      >
        <text
          id="_03"
          data-name="03"
          transform="translate(220 348)"
          fill="none"
          stroke="#cb9817"
          strokeWidth="1"
          fontSize="365"
          fontFamily="AlbertSans-Bold, Albert Sans"
          fontWeight="700"
          letterSpacing="-0.04em"
        >
          <tspan x="-218.818" y="0">
            03
          </tspan>
        </text>
      </svg>
    ),
    triggerName: "interior-trigger-3",
    circleTrigger: "circle-sm-3",
  },
  {
    id: 3,
    subHeading: "Photoshop",
    heading: "Post Production",
    description:
      "In the post-production phase, Photoshop becomes our enchanted wand, allowing us to weave intricate spells of digital artistry. Through expert manipulation of layers, colors, and textures, we breathe life into your vision, enhancing every pixel with finesse. From smoothing out imperfections to adding fantastical elements, each brushstroke is meticulously applied to transport viewers into a realm of awe and wonder. With Photoshop as our tool, we transcend reality, turning your project into a masterpiece of visual enchantment.",
    images: {
      first: "https://cdn.dastkaar.studio/website-images/images/d-4-a.webp",
      second: "https://cdn.dastkaar.studio/website-images/images/d-3-b.webp",
      third: "https://cdn.dastkaar.studio/website-images/images/d-3-c.webp",
    },
    imagesTrigger: {
      first: "img-4-a",
      second: "img-4-b",
    },
    backgroundImage: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="462"
        height="440"
        viewBox="0 0 462 440"
        className="svg-mobile"
      >
        <text
          id="_04"
          data-name="04"
          transform="translate(231 348)"
          fill="none"
          stroke="#cb9817"
          strokeWidth="1"
          fontSize="365"
          fontFamily="AlbertSans-Bold, Albert Sans"
          fontWeight="700"
          letterSpacing="-0.04em"
        >
          <tspan x="-229.768" y="0">
            04
          </tspan>
        </text>
      </svg>
    ),
    triggerName: "interior-trigger-4",
    circleTrigger: "",
  },
];

export const exteriorDesign = [
  {
    id: 0,
    subHeading: "Client Provides",
    heading: "Drawings",
    description:
      "Send us your site plan and dimensions, and let our expert team take care of the rest. From creating detailed 2D or 3D plans to providing accurate dimensions and elevations, we've got you covered every step of the way.",
    images: {
      first: "https://cdn.dastkaar.studio/website-images/images/d-1-a.webp",
      second: "https://cdn.dastkaar.studio/website-images/images/d-1-b.webp",
      third: "https://cdn.dastkaar.studio/website-images/images/d-1-c.webp",
    },
    backgroundImage: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="346"
        height="440"
        viewBox="0 0 346 440"
        className="svg-mobile"
      >
        <text
          id="_01"
          data-name="01"
          transform="translate(173 348)"
          fill="none"
          stroke="#cb9817"
          strokeWidth="1"
          fontSize="365"
          fontFamily="AlbertSans-Bold, Albert Sans"
          fontWeight="700"
          letterSpacing="-0.04em"
        >
          <tspan x="-171.185" y="0">
            01
          </tspan>
        </text>
      </svg>
    ),
    triggerName: "interior-trigger",
    imagesTrigger: {
      first: "img-1-a",
      second: "img-1-b",
    },
    circleTrigger: "circle-sm-1",
  },
  {
    id: 1,
    subHeading: "SKETCHUP & REVIT",
    heading: "3D Modeling",
    description:
      "After completing the drawing phase, we seamlessly transition to the 3D modeling stage. With expertise in both SketchUp and Revit, we adapt to your preference, ensuring precise and hyper-detailed 3D models tailored to your specifications",
    images: {
      first: "https://cdn.dastkaar.studio/website-images/images/d-2-a.webp",
      second: "https://cdn.dastkaar.studio/website-images/images/d-2-b.webp",
      third: "https://cdn.dastkaar.studio/website-images/images/d-2-c.webp",
    },
    imagesTrigger: {
      first: "img-2-a",
      second: "img-2-b",
    },
    backgroundImage: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="440"
        height="440"
        viewBox="0 0 440 440"
        className="svg-mobile"
      >
        <text
          id="_02"
          data-name="02"
          transform="translate(220 348)"
          fill="none"
          stroke="#cb9817"
          strokeWidth="1"
          fontSize="365"
          fontFamily="AlbertSans-Bold, Albert Sans"
          fontWeight="700"
          letterSpacing="-0.04em"
        >
          <tspan x="-218.452" y="0">
            02
          </tspan>
        </text>
      </svg>
    ),
    triggerName: "interior-trigger-2",
    circleTrigger: "circle-sm-2",
  },
  {
    id: 2,
    subHeading: "Lumion",
    heading: "Rendering",
    description:
      "After finalizing the 3D model to your satisfaction, we enter the 3D visualization phase, leveraging advanced rendering software like Lumion and D5. Our goal is to provide hyper-realistic visualizations that vividly portray your project, aiding your imagination in envisioning the exact look and feel of the final result.",
    images: {
      first: "https://cdn.dastkaar.studio/website-images/images/d-3-a.webp",
      second: "https://cdn.dastkaar.studio/website-images/images/d-3-b.webp",
      third: "https://cdn.dastkaar.studio/website-images/images/d-3-c.webp",
    },
    imagesTrigger: {
      first: "img-3-a",
      second: "img-3-b",
    },
    backgroundImage: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="440"
        height="440"
        viewBox="0 0 440 440"
        className="svg-mobile"
      >
        <text
          id="_03"
          data-name="03"
          transform="translate(220 348)"
          fill="none"
          stroke="#cb9817"
          strokeWidth="1"
          fontSize="365"
          fontFamily="AlbertSans-Bold, Albert Sans"
          fontWeight="700"
          letterSpacing="-0.04em"
        >
          <tspan x="-218.818" y="0">
            03
          </tspan>
        </text>
      </svg>
    ),
    triggerName: "interior-trigger-3",
    circleTrigger: "circle-sm-3",
  },
  {
    id: 3,
    subHeading: "Photoshop",
    heading: "Post Production",
    description:
      "In the post-production phase, Photoshop becomes our enchanted wand, allowing us to weave intricate spells of digital artistry. Through expert manipulation of layers, colors, and textures, we breathe life into your vision, enhancing every pixel with finesse. From smoothing out imperfections to adding fantastical elements, each brushstroke is meticulously applied to transport viewers into a realm of awe and wonder. With Photoshop as our tool, we transcend reality, turning your project into a masterpiece of visual enchantment.",
    images: {
      first: "https://cdn.dastkaar.studio/website-images/images/d-4-a.webp",
      second: "https://cdn.dastkaar.studio/website-images/images/d-3-b.webp",
      third: "https://cdn.dastkaar.studio/website-images/images/d-3-c.webp",
    },
    imagesTrigger: {
      first: "img-4-a",
      second: "img-4-b",
    },
    backgroundImage: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="462"
        height="440"
        viewBox="0 0 462 440"
        className="svg-mobile"
      >
        <text
          id="_04"
          data-name="04"
          transform="translate(231 348)"
          fill="none"
          stroke="#cb9817"
          strokeWidth="1"
          fontSize="365"
          fontFamily="AlbertSans-Bold, Albert Sans"
          fontWeight="700"
          letterSpacing="-0.04em"
        >
          <tspan x="-229.768" y="0">
            04
          </tspan>
        </text>
      </svg>
    ),
    triggerName: "interior-trigger-4",
    circleTrigger: "",
  },
];

export const threeD_Design = [
  {
    id: 0,
    subHeading: "Client Provides",
    heading: "Drawings",
    description:
      "Send us your site plan and dimensions, and let our expert team take care of the rest. From creating detailed 2D or 3D plans to providing accurate dimensions and elevations, we've got you covered every step of the way.",
    images: {
      first: "https://cdn.dastkaar.studio/website-images/images/d-1-a.webp",
      second: "https://cdn.dastkaar.studio/website-images/images/d-1-b.webp",
      third: "https://cdn.dastkaar.studio/website-images/images/d-1-c.webp",
    },
    imagesTrigger: {
      first: "img-1-a",
      second: "img-1-b",
    },
    backgroundImage: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="346"
        height="440"
        viewBox="0 0 346 440"
        className="svg-mobile"
      >
        <text
          id="_01"
          data-name="01"
          transform="translate(173 348)"
          fill="none"
          stroke="#cb9817"
          strokeWidth="1"
          fontSize="365"
          fontFamily="AlbertSans-Bold, Albert Sans"
          fontWeight="700"
          letterSpacing="-0.04em"
        >
          <tspan x="-171.185" y="0">
            01
          </tspan>
        </text>
      </svg>
    ),
    triggerName: "interior-trigger-1",
    circleTrigger: "circle-sm-1",
  },
  {
    id: 1,
    subHeading: "SKETCHUP & REVIT",
    heading: "3D Modeling",
    description:
      "After completing the drawing phase, we seamlessly transition to the 3D modeling stage. With expertise in both SketchUp and Revit, we adapt to your preference, ensuring precise and hyper-detailed 3D models tailored to your specifications",
    images: {
      first: "https://cdn.dastkaar.studio/website-images/images/d-2-a.webp",
      second: "https://cdn.dastkaar.studio/website-images/images/d-2-b.webp",
      third: "https://cdn.dastkaar.studio/website-images/images/d-2-c.webp",
    },
    imagesTrigger: {
      first: "img-2-a",
      second: "img-2-b",
    },
    backgroundImage: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="440"
        height="440"
        viewBox="0 0 440 440"
        className="svg-mobile"
      >
        <text
          id="_02"
          data-name="02"
          transform="translate(220 348)"
          fill="none"
          stroke="#cb9817"
          strokeWidth="1"
          fontSize="365"
          fontFamily="AlbertSans-Bold, Albert Sans"
          fontWeight="700"
          letterSpacing="-0.04em"
        >
          <tspan x="-218.452" y="0">
            02
          </tspan>
        </text>
      </svg>
    ),
    triggerName: "interior-trigger-2",
    circleTrigger: "circle-sm-2",
  },
  {
    id: 2,
    subHeading: "Lumion",
    heading: "Rendering",
    description:
      "After finalizing the 3D model to your satisfaction, we enter the 3D visualization phase, leveraging advanced rendering software like Lumion and D5. Our goal is to provide hyper-realistic visualizations that vividly portray your project, aiding your imagination in envisioning the exact look and feel of the final result.",
    images: {
      first: "https://cdn.dastkaar.studio/website-images/images/d-3-a.webp",
      second: "https://cdn.dastkaar.studio/website-images/images/d-3-b.webp",
      third: "https://cdn.dastkaar.studio/website-images/images/d-3-c.webp",
    },
    imagesTrigger: {
      first: "img-3-a",
      second: "img-3-b",
    },
    backgroundImage: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="440"
        height="440"
        viewBox="0 0 440 440"
        className="svg-mobile"
      >
        <text
          id="_03"
          data-name="03"
          transform="translate(220 348)"
          fill="none"
          stroke="#cb9817"
          strokeWidth="1"
          fontSize="365"
          fontFamily="AlbertSans-Bold, Albert Sans"
          fontWeight="700"
          letterSpacing="-0.04em"
        >
          <tspan x="-218.818" y="0">
            03
          </tspan>
        </text>
      </svg>
    ),
    triggerName: "interior-trigger-3",
    circleTrigger: "circle-sm-3",
  },
  {
    id: 3,
    subHeading: "Premier Pro",
    heading: "Post Production",
    description:
      "After reviewing the final renderings, we enter the post-production phase, where we cater to your aesthetic preferences with enchanting features such as meticulously crafted shadows, captivating details like drips, and an array of magical facade enhancements. Our goal is to elevate the visual appeal of your project to exceed your expectations, ensuring every aspect of the post-production process enhances the dreamy allure of the final result",
    images: {
      first: "https://cdn.dastkaar.studio/website-images/images/d-5-a.webp",
      second: "https://cdn.dastkaar.studio/website-images/images/d-3-b.webp",
      third: "https://cdn.dastkaar.studio/website-images/images/d-3-c.webp",
    },
    imagesTrigger: {
      first: "img-4-a",
      second: "img-4-b",
    },
    backgroundImage: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="462"
        height="440"
        viewBox="0 0 462 440"
        className="svg-mobile"
      >
        <text
          id="_04"
          data-name="04"
          transform="translate(231 348)"
          fill="none"
          stroke="#cb9817"
          strokeWidth="1"
          fontSize="365"
          fontFamily="AlbertSans-Bold, Albert Sans"
          fontWeight="700"
          letterSpacing="-0.04em"
        >
          <tspan x="-229.768" y="0">
            04
          </tspan>
        </text>
      </svg>
    ),
    triggerName: "interior-trigger-4",
    circleTrigger: "",
  },
];

export const resdentialData = [
  "Single-Family Houses",
  "Multi-Family Houses",
  "Apartments",
  "Bungalows",
  "Cottages",
  "Duplex/Triplex Houses",
  "Condominium",
  "Mansions & Townhouses",
  "Semi-detached houses",
];
export const commercialData = [
  "Shopping Centers & Malls",
  "General Stores",
  "Office buildings",
  "Restaurants & Hotels",
  "Warehouses",
  "Schools & Universities",
  "Hospitals & Clinics",
  "Theaters & Halls",
  "Senior Living Centers",
  "Industrial Buildings",
  "Powerplants",
  "Apartment Buildings",
  "Theaters",
  "Religious Buildings",
  "Self-Storage",
];
export const drywallData = [
  "Drywall Sheets",
  "Insulation",
  "Metal Studs",
  "Wood Studs",
  "Tracks for Studs",
  "Drywall Taping",
  "Drywall Mudding",
  "Cornerbeads",
  "Drywall Painting",
  "Screws",
];
export const masonryData = [
  "Unit Masonry",
  "CMU Blocks",
  "Glass Unit Masonry",
  "Concrete Form Masonry",
  "Stones (Granite, Marble, etc.)",
  "Glazed Tiles",
  "Facing Bricks",
  "Terra Cotta Estimate",
  "Stucco Work",
  "Mortar & Plaster Quantities",
  "Exterior Brick Replacement",
  "Masonry Anchorage",
  "Masonry Flashing",
  "Stonemasonry Walls",
];
export const steelData = [
  "Structural Steel",
  "Anchor Bolts and Rivets Estimate",
  "Cold-formed Steel Estimate",
  "Metal Panels & Cladding Estimate",
  "Light Gauge Metal Framing",
  "Reinforcing Steel Rebars",
  "Metal Panel Claddings",
  "Miscellaneous  Estimates",
];

export const lumberData1 = [
  "Wall Framing",
  "Floor Framing",
  "Roof Framing",
  "Structural Components",
];
export const lumberData2 = [
  "Framing for Wall Infill",
  "Roof Infill",
  "RTU Framing",
  "Plywood Decking",
  "Curbing",
];
export const lumberData3 = [
  "Wall Studs",
  "Floor Joists",
  "Roof Rafters",
  "Beams and Posts",
  "Wood Trusses",
  "Wooden Decking",
  "Plywood Sheathing",
  "Wood Paneling",
  "Wood Stairs & Railing",
  "Ornamental Woodwork",
  "Rough Carpentry",
  "Wood Shelving",
  "Fascia",
  "Trim and Siding",
  "Architectural Woodwork,",
];
export const flooringData1 = [
  "Armstrong Vinyl Composite Tiles (VCT)",
  "Resilient Tiles",
  "Carpets",
  "Luxury Vinyl Tile (LVT) Flooring",
  "Wooden Floors",
  "Rubber & Ceramic Tiles",
  "Concrete Floors",
];
export const flooringData2 = [
  "Wall Base",
  "Wainscoting",
  "Wall Tiles",
  "Cove Base",
];
export const concreteData1 = [
  "Driveways",
  "Foundations",
  "Pavements",
  "Pile Foundations",
  "Precast Panels",
  "Concrete Curbs",
  "Concrete Stairs",
  "Beams and Columns",
  "Concrete Slabs",
];
export const concreteData2 = [
  "Concrete Slabs",
  "Grade Beams",
  "Concrete Footings",
  "Concrete Driveway Estimates",
];
export const concreteData3 = [
  "Steel Rebar",
  "Steel Wire Mesh",
  "Formwork",
  "Stirrups & Dowels",
  "Ties & Anchors",
  "Excavation & Backfill",
  " Expansion Joints",
  "Control Joints",
  "Sealants",
];
export const electricalData = [
  "Light Fixtures",
  "Lighting Controls",
  "Wirings",
  "Cable Trays",
  "Conduits",
  "Receptacles",
  "Outlets",
  "Switches",
  "Low Voltage Supplies",
  "Feeders",
  "Distribution Boards",
  "Wiring Devices",
  "Grounding Materials",
  "Emergency Lights",
  "Exit Signs",
  "Circuit Breakers",
  "Junction Boxes",
  "Transformers",
  "Security Systems",
  "Audio Systems",
  "Video Systems",
  "Photovoltaic Systems",
  "Intercom Systems",
  "Fire Alarm Systems",
  "Switchboards",
  "Smoke Detectors",
];
export const estimationHomeData = [
  {
    name: "Residential Estimates",
    image: "/images/residential.webp",
    link: `${resdentialEstimates}`,
    description:
      "Our detailed resdential estimates include delivering precise estimations, detailed material analyses based on your blueprints, accurate cost assessments and material needs.",
  },
  {
    name: "Concrete Estimates",
    image: "/images/concrete.webp",
    link: `${concreteEstimates}`,
    description:
      "Our Concrete estimates include concrete, rebar and formwork quantities for foundations, concrete pavements, retaining walls, slabs, beam, columns etc.",
  },
  {
    name: "Flooring Estimates",
    image: "/images/flooring.webp",
    link: `${flooringEstimates}`,
    description:
      "We provide flooring takeoffs for all projects of all sizes. Our flooring estimates include quantities of tiles, carpet, wood floors, VCT, LVT, rubber floors any many more.",
  },
  {
    name: "Lumber Estimates",
    image: "/images/lumber.webp",
    link: `${lumberEstimates}`,
    description:
      "Lumber takeoff services include wood framing quantities for walls, floors, roofing an ceiling. Framing elements such as studs, joists, rafters,and outriggers quantities are estimated.",
  },
  {
    name: "Steel Estimates",
    image: "/images/steel.webp",
    link: `${steelEstimates}`,
    description:
      "We provide top-tier structural steel estimating services tailored to various framing projects. We cater to contractors,builders, fabricators, framers, steel erectors, and designers alike.",
  },
  {
    name: "Painting Estimates",
    image: "/images/painting-red.webp",
    link: `${paintingEstimates}`,
    description:
      "Our detailed painting estimates include priming, scraping and painting for walls, ceilings, doors & metal railings etc. We also estimate special coatings and wall coverings.",
  },
];

export const estimationData = [
  {
    name: "Residential Estimates",
    image: "/images/residential.webp",
    link: `${resdentialEstimates}`,
    description:
      "Our detailed resdential estimates include delivering precise estimations, detailed material analyses based on your blueprints, accurate cost assessments and material needs.",
  },
  {
    name: "Commercial Estimates",
    image: "/images/commercial.webp",
    link: `${commercialEstimates}`,
    description:
      "Our detailed commercial estimates includes services new construction, renovations, additions, and restorations with detailed material takeoffs and cost analysis.",
  },
  {
    name: "Concrete Estimates",
    image: "/images/concrete.webp",
    link: `${concreteEstimates}`,
    description:
      "Our Concrete estimates include concrete, rebar and formwork quantities for foundations, concrete pavements, retaining walls, slabs, beam, columns etc.",
  },
  {
    name: "Flooring Estimates",
    image: "/images/flooring.webp",
    link: `${flooringEstimates}`,
    description:
      "We provide flooring takeoffs for all projects of all sizes. Our flooring estimates include quantities of tiles, carpet, wood floors, VCT, LVT, rubber floors any many more.",
  },
  {
    name: "Lumber Estimates",
    image: "/images/lumber.webp",
    link: `${lumberEstimates}`,
    description:
      "Lumber takeoff services include wood framing quantities for walls, floors, roofing an ceiling. Framing elements such as studs, joists, rafters,and outriggers quantities are estimated.",
  },
  {
    name: "Steel Estimates",
    image: "/images/steel.webp",
    link: `${steelEstimates}`,
    description:
      "We provide top-tier structural steel estimating services tailored to various framing projects. We cater to contractors,builders, fabricators, framers, steel erectors, and designers alike.",
  },
  {
    name: "Painting Estimates",
    image: "/images/painting-red.webp",
    link: `${paintingEstimates}`,
    description:
      "Our detailed painting estimates include priming, scraping and painting for walls, ceilings, doors & metal railings etc. We also estimate special coatings and wall coverings",
  },
  {
    name: "Drywall Estimates",
    image: "/images/drywall.webp",
    link: `${drywallEstimates}`,
    description:
      "We provide detailed drywall takeoffs that include materials, labor, and equipment. Our seasoned estimators ensure precision and compliance with local construction codes. ",
  },
  {
    name: "Masonry Estimates",
    image: "/images/masonry.webp",
    link: `${masonryEstimates}`,
    description:
      "Our full masonry estimating service include CMU block estimate, brick work, stone work, masonry joint and grout infill, rebar infill, anchors, ties etc.",
  },
  {
    name: "Electical Estimates",
    image: "/images/electrical.webp",
    link: `${electricalEstimates}`,
    description:
      "Our electrical estimates include light fixtures & receptacles count, conduits and wiring lengths, electric panels count etc.",
  },
];
