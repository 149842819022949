import React, { useEffect, useLayoutEffect, useState } from "react";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import { Link } from "react-router-dom";

function EstimationProjects({ projects }) {
  gsap.registerPlugin(ScrollTrigger);
  useEffect(() => {
    let teamTrigger = gsap.timeline({
      scrollTrigger: {
        trigger: ".team-bg",
        toggleActions: "play resume restart reverse",
        scrub: 1,
        start: "0px bottom",
        pinSpacing: false,
      },
    });
    teamTrigger
      .to(".dee-image", {
        rotation: 360,
        duration: 3,
      })
      .to(".dee-image-2", {
        rotation: 360,
        duration: 3,
      });
  });
  return (
    <section
      className="section-padding-normal team-bg no-overflow estimation-box"
      style={{ position: "relative", zIndex: "3" }}
      data-lenis-prevent
    >
      <img
        className="dee-image-2"
        src={process.env.PUBLIC_URL + "/backgrounds/dee-2.svg "}
        alt="dee"
      />
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="team-heading-flex mt-50">
              <h2 className="minor-heading-2">
                Estimation Services
                <img
                  className="lines-top"
                  src={process.env.PUBLIC_URL + "/icons/lines-top.svg"}
                  alt="alt"
                />
                <img
                  className="lines-bottom"
                  src={process.env.PUBLIC_URL + "/icons/lines-bottom.svg"}
                  alt="alt"
                />
              </h2>
            </div>
          </div>
          <div className="col-lg-12 position-relative">
            <img
              className="dee-image"
              src={process.env.PUBLIC_URL + "/backgrounds/dee.svg "}
              alt="dee"
            />
          </div>
        </div>
        <div className="row team-box-padding">
          {projects.map((data, index) => {
            return (
              <>
                <div className="col-lg-4" key={index}>
                  <Link to={`/${data.link}`}>
                    <div className="team-box senior-box-margin">
                      <div className="estimate-image-container">
                        <img
                          src={process.env.PUBLIC_URL + `${data.image}`}
                          alt="alt"
                          className="estimate-picture"
                        />
                      </div>
                      <h2 className="estimate-name-senior">{data?.name}</h2>
                      <div className="align-items-space-between">
                        <p className="senior-role">{data?.description}</p>
                      </div>
                    </div>
                  </Link>
                </div>
              </>
            );
          })}
        </div>
      </div>
    </section>
  );
}

export default EstimationProjects;
