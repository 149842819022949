import React, { useEffect } from "react";
import ContactForm from "../Contact/ContactForm";
function BimText() {
  return (
    <>
      <section>
        <div className="container">
          <div className="row">
            <div className="col-lg-8 offset-lg-2 to-this-trigger">
              <h2 className="to-this-text">To this...</h2>
            </div>
          </div>
        </div>
      </section>
      <section className="bim-bg">
        <div className="section-padding-top">
          <div className="container">
            <div className="row">
              <div className="col-lg-10 offset-lg-1">
                <h2 className="solve-text">
                  Here to solve your{" "}
                  <span className="span-underline">problem</span>.
                </h2>
                <img
                  src="https://cdn.dastkaar.studio/website-images/images/bim-team.webp"
                  width="100%"
                  alt="bim-team"
                />
              </div>
            </div>
          </div>
        </div>
        <ContactForm />
      </section>
    </>
  );
}

export default BimText;
