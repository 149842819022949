import React, { useEffect } from "react";
import gsap from "gsap";
function BimBanner() {
  useEffect(() => {
    let bimHeadingTrigger = gsap.timeline({
      defaults: { ease: "Power3.easeInOut", duration: 1 },
    });
    bimHeadingTrigger.to(".bim-heading", {
      clipPath: "polygon(100% 0, 0 0, 0 100%, 100% 100%)",
      opacity: 1,
      x: 0,
      duration: 1.2,
    });
    let bimParagraph = gsap.timeline({
      defaults: { ease: "Power3.easeInOut", duration: 1 },
    });
    bimParagraph.to(".bim-text", {
      clipPath: "polygon(100% 0, 0 0, 0 100%, 100% 100%)",
      opacity: 1,
      x: 0,
      delay: 0.5,
      duration: 1.2,
    });
    let bimImage = gsap.timeline({
      defaults: { ease: "Power3.easeInOut", duration: 1 },
    });
    bimImage.to(".bim-banner-img", {
      opacity: 1,
      delay: 0.7,
      duration: 1.2,
    });
  });
  return (
    <section className="section-padding-normal bim-section">
      <div className="container">
        <div className="row bim-heading-center">
          <div className="col-lg-5">
            <h1 className="bim-heading">Building Information Modeling</h1>
            <p className="bim-text">Strong foundations last longer.</p>
          </div>
          <div className="col-lg-7">
            <img
              className="bim-banner-img"
              src="https://cdn.dastkaar.studio/website-images/images/bim-banner.webp"
              width="100%"
              alt="bim-banner"
            />
          </div>
        </div>
      </div>
    </section>
  );
}

export default BimBanner;
