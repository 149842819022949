import React from "react";

function HeroSection() {
  return (
    <section className="aboutpage-bg section-padding-normal about-trigger-item">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 offset-lg-3">
            <div className="about-heading-area">
              <h1 className="about-heading">Breathe a new life to your projects</h1>
              <img
                className="about-img"
                src={process.env.PUBLIC_URL + "/backgrounds/about-stroke.svg"}
                alt=""
              />
            </div>
          </div>
          <div className="col-lg-12">
            <p className="about-para">
              Dastkaar Studio is your ultimate destination for all your
              architectural design and estimation requirements. Our team
              consists of highly skilled architects, 3D visualizers, BIM and
              Estimation Experts who are dedicated to delivering exceptional
              results. At Dastkaar, we specialize in a wide range of
              architectural and estimation services, including residential and
              commercial design starting from the ground up, renovation
              projects, facade enhancements, exterior and interior design, 3D
              modeling, and photorealistic rendering.
            </p>
            <p className="about-para">
              We pride ourselves on utilizing cutting-edge digital design
              software, such as Revit, D5, Lumion,SketchUp,BlueBeam,
              PlanSwift, EarthworkPro ensuring that our work is of the highest
              quality and meets the latest industry standards. With our
              extensive experience, we have collaborated with numerous real
              estate agencies, construction companies, marketing agencies,
              design firms, and end users, establishing strong and successful
              partnerships.
            </p>
            {/* <p className="about-para">
              Choose Dastkaar Studio for unparalleled expertise, creativity, and
              professionalism in fulfilling all your architectural design and
              visualization needs.
            </p> */}
          </div>
        </div>
      </div>
    </section>
  );
}

export default HeroSection;
