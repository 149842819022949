import React from "react";

function Backdrop({ backdropHandler, handler }) {
  return (
    <div onClick={handler}>
      <div className="backdrop" onClick={backdropHandler}></div>
    </div>
  );
}

export default Backdrop;
