import React, { useEffect, useLayoutEffect, useRef } from "react";
import Footer from "../../Common/Footer/Footer";
import MovingBackground from "../../Common/MovingBackground";
import Navbar from "../../Nav/Navbar";
import Experience from "./Experience";
import HeroSection from "./HeroSection";
import Projects from "./Projects";
import Team from "./Team";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

import Sidedrawer from "../../Nav/Sidedrawer";
import ContactForm from "../Contact/ContactForm";
import EstimationProjects from "./EstimationProjects";
import { estimationHomeData } from "../../Data/Data";

function Main({ onToggle, show, handler, backdrop, projectsData, teamData }) {
  // useLayoutEffect(() => {
  //   //

  //   // let headerTimeline = gsap.timeline({
  //   //   scrollTrigger: {
  //   //     trigger: ".trigger-item",
  //   //     start: "bottom center",
  //   //     end: "bottom top",
  //   //     scrub: 1,
  //   //   },
  //   // });
  //   // headerTimeline
  //   //   .to(".header", {
  //   //     padding: "20px 0px",
  //   //     duration: 1,
  //   //   })
  //   //   .to(".navbar-main", {
  //   //     border: "1px solid #c9981a",
  //   //   });

  //   //   TeamComponentTrigger
  //   let teamTrigger = gsap.timeline({
  //     scrollTrigger: {
  //       trigger: ".team-bg",
  //       toggleActions: "play resume restart reverse",
  //       scrub: 1,
  //       start: "0px bottom",
  //       pinSpacing: false,
  //     },
  //   });
  //   teamTrigger
  //     .to(".dee-image", {
  //       rotation: 360,
  //       duration: 3,
  //     })
  //     .to(".dee-image-2", {
  //       rotation: 360,
  //       duration: 3,
  //     });

  //   //ResidentialProjectsTrigger

  //   //1 => Residential Title Span
  //   // let residentialTrigger = gsap.timeline({
  //   //   defaults: { ease: "Power3.easeInOut", duration: 1 },
  //   //   scrollTrigger: {
  //   //     trigger: ".residential-projects-box",
  //   //     toggleActions: "play resume restart reverse",
  //   //     once: true,
  //   //     start: "top bottom",
  //   //     scrub: 1,
  //   //   },
  //   // });
  //   // residentialTrigger.to(".project-name-span", {
  //   //   opacity: 1,
  //   //   clipPath: "polygon(100% 0px, 0px 0px, 0px 100%, 100% 100%)",
  //   //   transform: "translate(0px, 0px)",
  //   //   duration: 1.2,
  //   //   stagger: 0.2,
  //   // });

  //   //2 => Residential Main Heading
  //   // let residentialTrigger_2 = gsap.timeline({
  //   //   defaults: { ease: "Power3.easeInOut", duration: 1 },
  //   //   scrollTrigger: {
  //   //     trigger: ".residential-projects",
  //   //     scrub: 1,
  //   //   },
  //   // });
  //   // residentialTrigger_2.to(".residential-heading", {
  //   //   transform: "translateX(800px)",
  //   // });

  //   //3 => Residential Projects Image
  //   // let residentialTrigger_3 = gsap.timeline({
  //   //   defaults: { ease: "Power3.easeInOut", duration: 1 },
  //   //   scrollTrigger: {
  //   //     trigger: ".residential-projects",
  //   //     scrub: 1,
  //   //     start: "0px bottom",
  //   //   },
  //   // });
  //   // residentialTrigger_3.to(".project-img", {
  //   //   transform: "scale(1)",
  //   // });

  //   //CommercialProjectsTrigger

  //   //1 =>

  //   // let commericalTrigger = gsap.timeline({
  //   //   defaults: { ease: "Power3.easeInOut", duration: 1 },
  //   //   scrollTrigger: {
  //   //     trigger: ".commerical-projects-box",
  //   //     toggleActions: "play resume restart reverse",
  //   //     once: true,
  //   //     scrub: 1,
  //   //   },
  //   // });
  //   // commericalTrigger.to(".commercial-span", {
  //   //   opacity: 1,
  //   //   clipPath: "polygon(100% 0px, 0px 0px, 0px 100%, 100% 100%)",
  //   //   transform: "translate(0px, 0px)",
  //   //   duration: 1.2,
  //   //   stagger: 0.2,
  //   // });
  //   // let commericalTrigger_2 = gsap.timeline({
  //   //   defaults: { ease: "Power3.easeInOut", duration: 1 },
  //   //   scrollTrigger: {
  //   //     trigger: ".commerical-projects",
  //   //     scrub: 1,
  //   //   },
  //   // });
  //   // commericalTrigger_2.to(".commerical-heading", {
  //   //   transform: "translateX(800px)",
  //   // });
  //   // let commericalTrigger_4 = gsap.timeline({
  //   //   defaults: { ease: "Power3.easeInOut", duration: 1 },
  //   //   scrollTrigger: {
  //   //     trigger: ".commerical-projects",
  //   //     scrub: 1,
  //   //     start: "top center",
  //   //   },
  //   // });
  //   // commericalTrigger_4.to(".project-img-2", {
  //   //   transform: "scale(1)",
  //   // });

  //   ScrollTrigger.refresh();

  //   //
  // });

  return (
    <>
      <Navbar onToggle={onToggle} handler={handler} />
      <Sidedrawer onToggle={onToggle} show={show} handler={handler} />
      {backdrop}
      <main>
        <HeroSection />
        <Experience />
        <MovingBackground bg_name="projects-bg" heading="Projects" />
        <Projects projectsData={projectsData} />
        {/* <Services /> */}
        {/* <Team teamData={teamData} /> */}
        <EstimationProjects projects={estimationHomeData} />
        <ContactForm />
      </main>
      <Footer />
    </>
  );
}

export default Main;
