import { useNavigate } from "react-router-dom";
import { formatDate } from "../../utils/formatDate";
import { blogPage } from "../../utils/URLs";

const VerticalBlogCard = ({ blogData }) => {
  const navigate = useNavigate();
  const image = JSON.parse(blogData?.cover_image);

  return (
    <li
      className="blog-post-list-item"
      key={blogData?.id}
      onClick={() => navigate(`/${blogPage}/${blogData?.slug}`)}
    >
      <div className="blog-post-card">
        <div className="blog-post-card-featured-image">
          <img
            className="blog-post-card-featured-image"
            src={image[0]?.url}
            alt="blog"
          />
        </div>
        <div className="blog-post-card-body">
          <h3 className="blog-post-card-title">
            <h3 className="blog-post-card-title">
              {blogData?.title.split(" ").slice(0, 10).join(" ")}...
            </h3>
          </h3>
          <div className="blog-post-card-footer">
            <p className="blog-post-card-author">
              <a
                href={blogData?.redirect}
                target="_blank"
                rel="noopener noreferrer"
              >
                {blogData?.author}
              </a>
            </p>
            <p className="blog-post-card-date">
              {formatDate(blogData?.created_at)}
            </p>
          </div>
        </div>
      </div>
    </li>
  );
};
export default VerticalBlogCard;
