import React from "react";

function BackgroundHeading({ heading }) {
  return (
    <div className="container">
      <div className="row details-row">
        <h1 className="details-heading">{heading}</h1>
      </div>
    </div>
  );
}

export default BackgroundHeading;
