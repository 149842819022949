function DownloadPdfButton({ url }) {
  return (
    <div className="upload-plan">
      <a
        href={`${process.env.PUBLIC_URL}/pdf/${url}`}
        download="MyDocument.pdf"
      >
        Download PDF
      </a>
    </div>
  );
}

export default DownloadPdfButton;
