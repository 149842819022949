import React from "react";
import { Link } from "react-router-dom";
import {
  aboutUs,
  bim,
  exteriorDesign,
  floorPlanning,
  interiorDesign,
  projectsPage,
  walkthroughs,
} from "../../utils/URLs";
function FooterLink({ headingName }) {
  return (
    <>
      {headingName === "Company" ? (
        <div>
          <h6 className="footer-links-heading">{headingName}</h6>
          <Link to={`/${projectsPage}`}>
            <p className="footer-sm-links">Projects</p>
          </Link>
          <Link to={`/${interiorDesign}`}>
            <p className="footer-sm-links">Interior Design</p>
          </Link>
          <Link to={`/${exteriorDesign}`}>
            <p className="footer-sm-links">Exterior Design</p>
          </Link>
          <Link to={`/${walkthroughs}`}>
            <p className="footer-sm-links">3D Walkthroughs</p>
          </Link>
          <Link to={`/${bim}`}>
            <p className="footer-sm-links">BIM</p>
          </Link>
          {/* <Link to={`/${floorPlanning}`}>
            <p className="footer-sm-links">Floor Planning</p>
          </Link> */}
          <Link to={`/${aboutUs}`}>
            <p className="footer-sm-links">About Us</p>
          </Link>
        </div>
      ) : (
        <div>
          <h6 className="footer-links-heading">{headingName}</h6>
          <a href="https://www.instagram.com/dastkaar.studio/" target="_blank">
            <p className="footer-sm-links">Instagram</p>
          </a>
          <a
            href="https://www.linkedin.com/company/dastkaar-studio/?viewAsMember=true"
            target="_blank"
          >
            <p className="footer-sm-links">LinkedIn</p>
          </a>
          <a
            href="https://www.facebook.com/profile.php?id=100094066855153"
            target="_blank"
          >
            <p className="footer-sm-links">Facebook</p>
          </a>
        </div>
      )}
    </>
  );
}

export default FooterLink;
