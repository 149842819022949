import EstimationCategories from "../../Common/EstimationCategories";
import EstimationContact from "../../Common/EstimationContact";
import ServicesList from "../../Common/ServicesList";
import { electricalData } from "../../Data/Data";
import gsap from "gsap";

const Main = () => {

  return (
    <section className="section-padding-normal " data-lenis-prevent>
      <div className="container">
        <div className="row">
          <div className="col-lg-9">
            <h1 className="estimate-heading">
              ELECTICAL TAKEOFF & ESTIMATING SERVICES
            </h1>
            <p className="minor-description-3">
              Preparing electrical estimates for residential, commercial
              building, and renovation projects can indeed be a time-consuming
              and costly endeavor for contractors. That's why savvy businesses
              turn to Dastkaar Studio for their estimation needs. Entrusting
              professionals with your estimating requirements not only increases
              your chances of winning bids, but also saves you valuable time and
              resources.
            </p>
            <p className="minor-description-3">
              At Dastkaar Studio, we specialize in providing accurate and
              comprehensive electrical estimates tailored to the specific needs
              of each project. Our experienced team understands the complexities
              involved in electrical estimating, from understanding project
              requirements to factoring in material costs and labor expenses.
            </p>
            <p className="minor-description-3">
              By leveraging our expertise, contractors can focus their energies
              on other aspects of project management, confident that their
              estimating needs are in capable hands. With Dastkaar Studio
              handling your electrical estimates, you can streamline your
              bidding process, maximize profitability, and ultimately achieve
              success in your projects.
            </p>
            <h1 className="estimate-heading mt-50">
              Why We Standout in Electical Takeoff Services:
            </h1>
            <p className="minor-description-3">
              We prioritize the ongoing professional development of our
              estimators, keeping them abreast of the latest industry knowledge
              and techniques. Our certified estimators hold accreditations from
              esteemed organizations such as{" "}
              <span className="span-color">ASPE, NECA, PMI, and LEED AP</span>,
              demonstrating our commitment to excellence.
            </p>
            <p className="minor-description-3">
              Adhering strictly to code compliance and regulations, we guarantee
              that our estimates meet the highest standards of quality and
              accuracy. Our meticulous quality assurance procedure ensures that
              no item is overlooked during the cost estimation process. Each
              estimating spreadsheet undergoes multiple review stages by our
              senior estimators before final delivery to our clients, ensuring
              thoroughness and reliability.
            </p>
            <h1 className="estimate-heading mt-50">
              Why We Offer in Electical Takeoff Services:
            </h1>
            <p className="minor-description-3">
              When collaborating with Dastkaar Studio, you're guaranteed a
              professional estimate and breakdown of materials for your
              electrical needs, enabling you to submit accurate bids and secure
              successful projects. Our certified electrical estimators are adept
              at quantifying various components essential for project
              completion. We possess hands-on experience in preparing detailed
              electrical estimates for both residential and commercial
              construction projects.
            </p>
            <p className="minor-description-3">
              In our electrical takeoffs, we meticulously include the following
              major components:
            </p>
            <div className="row">
              <ServicesList services={electricalData} />
            </div>
            <div className="row mt-50">
              <div className="col-lg-6">
                <img
                  className="estimate-img"
                  src={process.env.PUBLIC_URL + "/images/electrical.webp"}
                  alt="resdential"
                />
              </div>
              <div className="col-lg-6">
                <img
                  className="estimate-img"
                  src={process.env.PUBLIC_URL + "/images/residential-pdf.webp"}
                  alt="resdential"
                />
              </div>
            </div>
            <p className="minor-description-3">
              With Dastkaar Studio, you can trust that our electrical estimates
              are thorough, accurate, and tailored to your project's specific
              requirements. Let us assist you in securing successful bids and
              delivering exceptional results in your construction endeavors.
            </p>
            <p className="minor-description-3">
              At Dastkaar Studio, we go beyond simple estimation services and
              offer a comprehensive suite of electrical solutions tailored to
              meet the diverse needs of our clients. Our skilled estimators are
              proficient in estimating electrical scopes for various project
              types, encompassing materials, labor, and equipment essential for
              successful project completion. Here's a list of the electrical
              services we provide:
            </p>

            <p className="minor-description-3">
              {/* <span className="span-color">Preliminary Estimates:</span> Initial
              estimates to provide a rough idea of project costs.
              <br /> */}
              <span className="span-color">Budget Estimates:</span> Detailed
              estimates to assist in budget planning and allocation of
              resources.
              <br />
              <span className="span-color">Residential Estimates:</span>{" "}
              Estimation services tailored specifically for residential
              projects.
              <br />
              <span className="span-color">Commercial Estimates:</span>{" "}
              Estimation services designed for commercial construction projects.
              <br />
              <span className="span-color">Industrial Estimates:</span>{" "}
              Estimation services catering to the unique requirements of
              industrial projects.
              <br />
              <span className="span-color">Electrical Bidding:</span> Assistance
              with preparing competitive bids for electrical contracts.
              <br />
              <span className="span-color">Electrical Takeoffs:</span> Detailed
              quantification of electrical materials required for projects.
              <br />
              <span className="span-color">Electrical CAD Drafting:</span>{" "}
              Creation of precise CAD drawings for electrical layouts and
              designs.
              <br />
              <span className="span-color">Project Scheduling:</span>{" "}
              Development of project schedules to ensure timely completion.
              {/* <br />
              <span className="span-color">Cost Control:</span> Monitoring and
              management of project costs to prevent budget overruns.
              <br />
              <span className="span-color">Project Leads:</span> Providing leads
              and opportunities for potential electrical projects. */}
            </p>
            <p className="minor-description-3">
              With Dastkaar Studio, you can rely on our expertise to streamline
              your electrical projects from estimation to execution, ensuring
              efficiency, accuracy, and successful outcomes. Let us be your
              trusted partner in delivering high-quality electrical solutions
              for your construction endeavors.
            </p>
          </div>
          <div className="col-lg-3">
            <EstimationCategories />
            <EstimationContact />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Main;
