export const projectsPage = "project";
export const projectDetailPage = "projectDetailPage";
export const services = "services";
export const aboutUs = "about";
export const contactUs = "contact";
export const interiorDesign = "interior";
export const exteriorDesign = "exterior";
export const walkthroughs = "3DwalkThroughs";
export const bim = "bim";
export const floorPlanning = "floorPlanning";
export const resdentialEstimates = "resdential-estimates";
export const commercialEstimates = "commercial-estimates";
export const drywallEstimates = "drywall-estimates";
export const masonryEstimates = "masonry-estimates";
export const steelEstimates = "steel-estimates";
export const lumberEstimates = "lumber-estimates";
export const flooringEstimates = "flooring-estimates";
export const concreteEstimates = "concrete-estimates";
export const electricalEstimates = "electrical-estimates";
export const mepEstimates = "mep-estimates";
export const paintingEstimates = "painting-estimates";
export const estimationPage = "estimation";
export const blogspage = "blogs";
export const blogPage = "blog";





