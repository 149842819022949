import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Footer from "../../Common/Footer/Footer";
import Loader from "../../Common/Loader";
import Navbar from "../../Nav/Navbar";
import Sidedrawer from "../../Nav/Sidedrawer";
import BackgroundHeading from "./BackgroundHeading";
import BackgroundImg from "./BackgroundImg";
import BannerSection from "./BannerSection";
import ClientReview from "./ClientReview";
import ImagesBox from "./ImagesBox";

import MiddleSection from "./MiddleSection";

function ProjectDetail({ btoa, onToggle, handler, show, backdrop }) {
  const [detail, setDetail] = useState();
  const [loading, setLoading] = useState(true);
  const [clientName, setClientName] = useState();
  const [projectName, setProjectName] = useState("");

  let pathname = window.location.pathname.slice(19);

  const fetchProjectDetail = async () => {
    if (detail === undefined) {
      try {
        const data = await fetch(
          `${process.env.REACT_APP_BASE_URL}/project/${pathname}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Basic " + btoa,
            },
          }
        );
        const response = await data.json();
        setDetail(response);
        setProjectName(response?.name);
        fetchClientForProject(response?.feedback?.client);
        if (data?.status === 200) {
          setLoading(false);
        }
      } catch (error) {
        console.log(console.error);
      }
    }
  };
  const fetchClientForProject = async (id) => {
    if (clientName === undefined) {
      try {
        const data = await fetch(
          `${process.env.REACT_APP_BASE_URL}/client/${id}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Basic " + btoa,
            },
          }
        );
        const response = await data.json();
        setClientName(response?.name);
      } catch (error) {
        console.log(console.error);
      }
    }
  };

  useEffect(() => {
    if (projectName === "") {
      document.title = `Dastkaar`;
    } else {
      document.title = `${projectName} | Dastkaar`;
    }
  });

  useEffect(() => {
    fetchProjectDetail();
  }, []);

  return (
    <div data-lenis-prevent>
      {loading ? (
        <Loader />
      ) : (
        <>
          <Navbar onToggle={onToggle} handler={handler} />
          <Sidedrawer onToggle={onToggle} show={show} handler={handler} />
          {backdrop}
          <main>
            <BannerSection
              name={detail?.name}
              year={detail?.date}
              location={detail?.location}
              description={detail?.description}
              firstPicture={
                detail?.images[0]?.image_url
                  ? detail?.images[0]?.image_url
                  : detail?.images[0]?.image
              }
              clientName={clientName}
              area={`${detail?.area} Sq. Ft`}
            />
            <BackgroundHeading heading="DETAILS" />
            <BackgroundImg
              bg={
                detail?.coverImages[0]?.image_url
                  ? detail?.coverImages[0]?.image_url
                  : detail?.coverImages[0]?.image
              }
            />
            <section className="section-padding-normal">
              {detail?.images.map((i, index) => (
                <div key={index}>
                  {index === 0 ? (
                    <></>
                  ) : (
                    <MiddleSection
                      middleImage={i.image_url ? i.image_url : i?.image}
                    />
                  )}
                </div>
              ))}
              {/* <MiddleSection middleImage={detail?.images[1]?.image} /> */}
              {/* <MiddleSection middleImage={detail?.images[2]?.image} /> */}
              {/* <MiddleSection middleImage={detail?.images[3]?.image} /> */}
            </section>
            <BackgroundImg
              bg={
                detail?.coverImages[1]?.image_url
                  ? detail?.coverImages[1]?.image_url
                  : detail?.coverImages[1]?.image
              }
            />
            <ClientReview
              name={clientName}
              location={detail?.location}
              feedback={detail?.feedback?.feedback}
            />
          </main>
          <Footer />
        </>
      )}
    </div>
  );
}

export default ProjectDetail;
