import React, { useEffect } from "react";
import gsap from "gsap";

function Banner() {
  useEffect(() => {
    let exteriorHeading = gsap.timeline({
      defaults: { ease: "Power3.easeInOut", duration: 1 },
    });
    exteriorHeading.to(".exterior-heading", {
      clipPath: "polygon(100% 0, 0 0, 0 100%, 100% 100%)",
      opacity: 1,
      x: 0,
      duration: 1.2,
    });
    let exteriorDesignDescription = gsap.timeline({
      defaults: { ease: "Power3.easeInOut", duration: 1 },
    });
    exteriorDesignDescription.to(".exterior-service-description-trigger", {
      clipPath: "polygon(100% 0, 0 0, 0 100%, 100% 100%)",
      opacity: 1,
      x: 0,
      duration: 1.2,
    });
  });
  return (
    <section className="exterior-design-bg services-design-bg align-row-banner">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 offset-lg-3">
            <div className="flex-center">
              <p className="service-description exterior-service-description-trigger">
                We offer the best
              </p>
            </div>
            <h1 className="service-heading exterior-heading">
              Exterior Design, <br />
              at it's best.
            </h1>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Banner;
