import React from "react";

function MovingBackground({ bg_name, heading }) {
  return (
    <section className="no-overflow" data-lenis-prevent>
      <div className={`moving-bg ${bg_name}`}></div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12">
            <div className="team-heading-flex">
              <h2 className="minor-heading">
                {heading}
                <img
                  className="lines-top"
                  src={process.env.PUBLIC_URL + "/icons/lines-top.svg"}
                  alt="alt"
                />
                <img
                  className="lines-bottom"
                  src={process.env.PUBLIC_URL + "/icons/lines-bottom.svg"}
                  alt="alt"
                />
              </h2>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default MovingBackground;
