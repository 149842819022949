import React from "react";

const ServicesList = ({ services, single }) => {
  const middleIndex = Math.ceil(services.length / 2);
  const firstColumnItems = services.slice(0, middleIndex);
  const secondColumnItems = services.slice(middleIndex);

  return (
    <>
      {single ? (
        <div className="col-lg-12  list-main">
          <ul className="ul-list">
            {services.map((service) => (
              <li className="list-text ">
                <span className="span-color">&#10003;</span> {service}
              </li>
            ))}
          </ul>
        </div>
      ) : (
        <>
          <div className="col-lg-6 col-md-12 list-main">
            <ul className="ul-list">
              {firstColumnItems.map((service) => (
                <li className="list-text ">
                  <span className="span-color">&#10003;</span> {service}
                </li>
              ))}
            </ul>
          </div>
          <div className="col-lg-6 col-md-12 list-main">
            <ul className="ul-list">
              {secondColumnItems.map((service) => (
                <li className="list-text ">
                  <span className="span-color">&#10003;</span> {service}
                </li>
              ))}
            </ul>
          </div>
        </>
      )}
    </>
  );
};

export default ServicesList;
