import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import VerticalBlogCard from "./VerticalBlogCard";

export const BlogSwiper = ({ blogData }) => {
  console.log("blogData", blogData);
  return (
    <Swiper
      modules={[Navigation, Pagination, Scrollbar, A11y]}
      spaceBetween={50}
      slidesPerView={3}
      navigation
      className="slides"
      // pagination={{ clickable: true }}
      breakpoints={{
        220: {
          slidesPerView: 1,
          spaceBetween: 20,
        },
        768: {
          slidesPerView: 2,
          spaceBetween: 30,
        },
        1024: {
          slidesPerView: 3,
          spaceBetween: 50,
        },
      }}
      onSwiper={(swiper) => console.log(swiper)}
      onSlideChange={() => console.log("slide change")}
    >
      <SwiperSlide>
        <VerticalBlogCard blogData={blogData} />
      </SwiperSlide>
      <SwiperSlide>
        <VerticalBlogCard blogData={blogData} />
      </SwiperSlide>
      <SwiperSlide>
        <VerticalBlogCard blogData={blogData} />
      </SwiperSlide>
      <SwiperSlide>
        <VerticalBlogCard blogData={blogData} />
      </SwiperSlide>
      <SwiperSlide>
        <VerticalBlogCard blogData={blogData} />
      </SwiperSlide>
    </Swiper>
  );
};
