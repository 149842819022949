import EstimationCategories from "../../Common/EstimationCategories";
import EstimationContact from "../../Common/EstimationContact";
import ServicesList from "../../Common/ServicesList";
import { flooringData1, flooringData2 } from "../../Data/Data";

const Main = () => {
  return (
    <section className="section-padding-normal " data-lenis-prevent>
      <div className="container">
        <div className="row">
          <div className="col-lg-9">
            <h1 className="estimate-heading">
              FLOORING TAKEOFF & ESTIMATING SERVICES
            </h1>
            <p className="minor-description-3">
              For flooring contractors or supplier companies, managing flooring
              takeoffs can be a time-consuming task, especially for large
              commercial construction projects. While manual calculations may
              suffice for smaller residential projects, utilizing construction
              estimating software like PlanSwift or Bluebeam becomes essential
              for more extensive commercial jobs.
            </p>
            <p className="minor-description-3">
              At Dastkaar Studio, we understand the challenges faced by busy
              flooring contractors and suppliers. That's why we offer{" "}
              <span className="span-color">
                professional flooring estimating services{" "}
              </span>{" "}
              to help you streamline your workflow and focus on other critical
              aspects of managing bids and the supply chain.
            </p>
            <p className="minor-description-3">
              Our flooring takeoff services provide a detailed list of floor
              material quantities categorized by the type of{" "}
              <span className="span-color">floor finishes</span> used in the
              project. Typical inclusions are the square footage of tiles,
              carpet flooring, wood flooring, as well as the linear footage of
              cove base and the number of transition strips, among others.
            </p>
            <p className="minor-description-3">
              By outsourcing your flooring estimating needs to Dastkaar Studio,
              you can save valuable time and resources, allowing you to
              concentrate on core business activities while ensuring accurate
              and efficient project management. Let us handle the complexities
              of flooring takeoffs, so you can achieve greater productivity and
              success in your projects.
            </p>
            <h1 className="estimate-heading mt-50">
              Why We Standout in Flooring Takeoff Services:
            </h1>
            <p className="minor-description-3">
              At Dastkaar Studio, our team comprises highly qualified floor
              estimators equipped with the expertise and tools necessary to
              swiftly and accurately estimate a wide range of floor finishes.
              From <span className="span-color">VCT tiles to LVT tiles</span>,
              carpets, rubber tiling, and wood flooring, we specialize in
              providing comprehensive estimates for various flooring materials.
            </p>
            <p className="minor-description-3">
              Our flooring estimators excel in handling large-scale commercial
              projects, leveraging the latest software like{" "}
              <span className="span-color">PlanSwift</span> to ensure efficiency
              and precision in our estimations. With experience in preparing
              flooring estimates for diverse settings such as apartments,
              hotels, hospitals, arenas, and schools, we understand the unique
              requirements of each project type.
            </p>
            <p className="minor-description-3">
              Recognizing the critical importance of accuracy in flooring
              measurements, we prioritize thoroughness in our process. Before
              the final delivery of our construction takeoffs, we dedicate extra
              time to double-checking each and every quantity. This meticulous
              approach ensures that all quantities are precise and reliable,
              facilitating successful bids and material orders for our clients.
            </p>
            <p className="minor-description-3">
              Choose Dastkaar Studio for flooring estimating services that
              combine expertise, efficiency, and unwavering{" "}
              <span className="span-color">commitment to accuracy</span>,
              empowering you to achieve excellence in your construction
            </p>
            <div className="row mt-50">
              <div className="col-lg-6">
                <img
                  className="estimate-img"
                  src={process.env.PUBLIC_URL + "/images/flooring.webp"}
                  alt="resdential"
                />
              </div>
              <div className="col-lg-6">
                <img
                  className="estimate-img"
                  src={process.env.PUBLIC_URL + "/images/flooring-pdf.webp"}
                  alt="resdential"
                />
              </div>
            </div>
            <h1 className="estimate-heading  mt-50">
              What We Offer in Flooring Takeoff Services:
            </h1>
            <p className="minor-description-3">
              At Dastkaar Studio, our flooring takeoff and estimates are
              meticulously prepared in an Excel spreadsheet format, ensuring
              clarity and organization for our clients. Our comprehensive
              spreadsheets detail the quantities of various floor areas as
              specified in the finish schedule provided in the drawings.
              <br />
              We specialize in estimating a wide range of flooring materials,
              including but not limited to:
            </p>
            <div className="row">
              <ServicesList services={flooringData1} single={true} />
            </div>
            <p className="minor-description-3">
              Additionally, we understand that the scope of flooring projects
              often extends beyond just the floor surfaces. Therefore, our
              estimates also encompass quantities for related elements such as:
            </p>
            <div className="row">
              <ServicesList services={flooringData2} single={true} />
            </div>

            <p className="minor-description-3">
              At Dastkaar Studio, we tailor our estimates to meet the specific
              requirements of each client, ensuring accuracy and thoroughness in
              every detail. Trust us to provide you with comprehensive flooring
              takeoff and estimates that serve as invaluable tools for project
              planning and execution. construction endeavors.
            </p>
          </div>
          <div className="col-lg-3">
            <EstimationCategories />
            <EstimationContact />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Main;
