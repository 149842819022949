import React, { useEffect, useState } from "react";
import MovingBackground from "../../Common/MovingBackground";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import ProcessLines from "./ProcessLines";

function Process({ props }) {
  //
  const res = props;

  //
  const desktopStyle = "250px";
  const mobileStyle = "150px";

  let mobileQuery = "(max-width:490px)";
  const [styleMap, setStyleMap] = useState(desktopStyle);

  useEffect(() => {
    if (window.matchMedia(mobileQuery).matches === true) {
      setStyleMap(mobileStyle);
    }
  }, [window.innerWidth]);

  console.log(styleMap);

  //
  gsap.registerPlugin(ScrollTrigger);
  useEffect(() => {
    let interior_img_1a = gsap.timeline({
      defaults: { ease: "Power3.easeInOut", duration: 1 },
      scrollTrigger: {
        trigger: ".interior-trigger",
        scrub: 1,
        duration: 1.2,
      },
    });
    interior_img_1a.to(".img-1-a", {
      transform: "translateY(30px)",
    });
    let interior_img1b = gsap.timeline({
      defaults: { ease: "Power3.easeInOut", duration: 1 },
      scrollTrigger: {
        trigger: ".interior-trigger",
        scrub: 1,
        duration: 1.2,
      },
    });
    interior_img1b.to(".img-1-b", {
      transform: "translateY(30px)",
    });
    let interior_circles = gsap.timeline({
      defaults: { ease: "Power3.easeInOut", duration: 1 },
      scrollTrigger: {
        trigger: ".interior-trigger",
        scrub: 1,
      },
    });
    interior_circles.to(".circle-sm-1", {
      transform: `translateY(${styleMap})`,
    });
    let interior_img_2a = gsap.timeline({
      defaults: { ease: "Power3.easeInOut", duration: 1 },
      scrollTrigger: {
        trigger: ".interior-trigger-2",
        scrub: 1,
        duration: 1.2,
        // markers: true,
      },
    });
    interior_img_2a.to(".img-2-a", {
      transform: "translateY(30px)",
    });
    let interior_img2b = gsap.timeline({
      defaults: { ease: "Power3.easeInOut", duration: 1 },
      scrollTrigger: {
        trigger: ".interior-trigger-2",
        scrub: 1,
        duration: 1.2,
      },
    });
    interior_img2b.to(".img-2-b", {
      transform: "translateY(30px)",
    });
    let interior_img_3a = gsap.timeline({
      defaults: { ease: "Power3.easeInOut", duration: 1 },
      scrollTrigger: {
        trigger: ".interior-trigger-3",
        scrub: 1,
        duration: 1.2,
      },
    });
    interior_img_3a.to(".img-3-a", {
      transform: "translateY(30px)",
    });
    let interior_img_3b = gsap.timeline({
      defaults: { ease: "Power3.easeInOut", duration: 1 },
      scrollTrigger: {
        trigger: ".interior-trigger-3",
        scrub: 1,
        duration: 1.2,
      },
    });
    interior_img_3b.to(".img-3-b", {
      transform: "translateY(30px)",
    });
    let interior_img_4a = gsap.timeline({
      defaults: { ease: "Power3.easeInOut", duration: 1 },
      scrollTrigger: {
        trigger: ".interior-trigger-4",
        scrub: 1,
        duration: 1.2,
      },
    });
    interior_img_4a.to(".img-4-a", {
      transform: "translateY(30px)",
    });
    let interior_img_4b = gsap.timeline({
      defaults: { ease: "Power3.easeInOut", duration: 1 },
      scrollTrigger: {
        trigger: ".interior-trigger-4",
        scrub: 1,
        duration: 1.2,
      },
    });
    interior_img_4b.to(".img-4-b", {
      transform: "translateY(30px)",
    });
    let interior_circles_2 = gsap.timeline({
      defaults: { ease: "Power3.easeInOut", duration: 1 },
      scrollTrigger: {
        trigger: ".interior-trigger-2",
        scrub: 1,
      },
    });
    interior_circles_2.to(".circle-sm-2", {
      transform: `translateY(${styleMap})`,
    });
    let interior_circles_3 = gsap.timeline({
      defaults: { ease: "Power3.easeInOut", duration: 1 },
      scrollTrigger: {
        trigger: ".interior-trigger-3",
        scrub: 1,
      },
    });
    interior_circles_3.to(".circle-sm-3", {
      transform: `translateY(${styleMap})`,
    });

    //Refresh
    ScrollTrigger.refresh();
    //
  });
  return (
    <>
      <MovingBackground bg_name="process-bg" heading="Our Process" />
      <section
        className="section-padding-normal interior-images-trigger"
        data-lenis-prevent
      >
        <div className="container">
          {res.map((i, index) => (
            <div key={index}>
              <div
                className={`row bg-white zindex-3 ${
                  styleMap === "150px" ? "" : i?.triggerName
                }`}
              >
                <div className="interior-line bottom-0"></div>
                <div className="interior-line top-0"></div>
                <div className="col-lg-6">
                  <div className="interior-process">
                    <div
                      className={`interior-img-container img-a ${i?.imagesTrigger?.first}`}
                    >
                      <img
                        className="interior-img"
                        src={i?.images?.first}
                        alt="first"
                      />
                    </div>
                    <div
                      className={`interior-img-container img-b ${i?.imagesTrigger?.second}`}
                    >
                      <img
                        className="interior-img"
                        src={i?.images?.second}
                        alt="second"
                      />
                    </div>
                    <div className="interior-img-container">
                      <img
                        className="interior-img"
                        src={i?.images?.third}
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                <div
                  className={`col-lg-6 interior-process ${
                    styleMap === "150px" ? "" : i?.triggerName
                  }`}
                >
                  <div className="interior-process-box">
                    <div className="width-100">
                      <p className="interior-software">{i?.subHeading}</p>
                      <h6 className="interior-step-name">{i?.heading}</h6>
                      <p className="interior-step-description">
                        {i?.description}
                      </p>
                    </div>
                    <div style={{ position: "absolute", opacity: "0.4" }}>
                      {i?.backgroundImage}
                    </div>
                  </div>
                </div>
              </div>
              {i?.circleTrigger !== "" ? (
                <ProcessLines actionClass={i?.circleTrigger} />
              ) : (
                <></>
              )}
            </div>
          ))}
        </div>
      </section>
    </>
  );
}

export default Process;
