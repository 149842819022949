import React from "react";

function ChangeImages() {
  return (
    <>
      <section className="section-padding-normal">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 offset-lg-2 to-this-trigger">
              <h2 className="from-this-text">We go from this</h2>
            </div>
          </div>
        </div>
      </section>
      <section className="overscroll-img">
        <div className="comparisonSection">
          <div className="comparisonImage beforeImage">
            <img
              src="https://cdn.dastkaar.studio/website-images/images/bim-sketch.svg"
              alt=""
              style={{ width: "100%" }}
            />
          </div>

          <div className="comparisonImage afterImage afterImage-bg">
            <img
              src="https://cdn.dastkaar.studio/website-images/images/testing-sketch-2.png"
              alt=""
              style={{ width: "100%" }}
            />
          </div>
        </div>
      </section>
    </>
  );
}

export default ChangeImages;
