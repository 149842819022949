import React from "react";
import { Link } from "react-router-dom";
import Toggle from "../DarkMode/Toggle";
import {
  aboutUs,
  bim,
  blogspage,
  contactUs,
  estimationPage,
  exteriorDesign,
  interiorDesign,
  projectsPage,
  walkthroughs,
} from "../utils/URLs";

function Sidedrawer({ show, handler, onToggle }) {
  let drawerClasses = ["side-drawer"];
  if (show) {
    drawerClasses = ["side-drawer open"];
  }
  return (
    <nav className={drawerClasses} onClick={handler}>
      <ul>
        <div style={{ marginBottom: "25px" }}>
          <Toggle onToggle={onToggle} />
        </div>
        <li>
          <Link to="/" className="side-drawer-links">
            Home
          </Link>
        </li>
        <li>
          <Link to={`/${projectsPage}`} className="side-drawer-links">
            Projects
          </Link>
        </li>
        <li>
          <Link to={`/${interiorDesign}`} className="side-drawer-links">
            Interior Design
          </Link>
        </li>
        <li>
          <Link to={`/${exteriorDesign}`} className="side-drawer-links">
            Exterior Design
          </Link>
        </li>
        <li>
          <Link to={`/${walkthroughs}`} className="side-drawer-links">
            3d Visualisation
          </Link>
        </li>
        <li>
          <Link to={`/${bim}`} className="side-drawer-links">
            BIM
          </Link>
        </li>
        <li>
          <Link to={`/${blogspage}`} className="side-drawer-links">
            Blogs
          </Link>
        </li>
        <li>
          <Link to={`/${aboutUs}`} className="side-drawer-links">
            About
          </Link>
        </li>
        <li>
          <Link to={`/${contactUs}`} className="side-drawer-links">
            Contact
          </Link>
        </li>
        <li>
          <Link to={`/${estimationPage}`} className="side-drawer-links">
            Estimation
          </Link>
        </li>
      </ul>
    </nav>
  );
}

export default Sidedrawer;
