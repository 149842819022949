import { Link } from "react-router-dom";
import { contactUs } from "../utils/URLs";

const EstimationContact = () => {
  return (
    <>
      <div className="catgories-main">
        <h1 className="minor-heading-69">CONTACT US</h1>
        <p className="minor-description-4">+1 (628) 201-6728</p>
        <p className="minor-description-4">info@dastkaar.studio</p>
        <Link to={`/${contactUs}`}>
          <div className="upload-plan">Upload Plan</div>
        </Link>
      </div>
    </>
  );
};
export default EstimationContact;
