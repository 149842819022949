import { useNavigate } from "react-router-dom";
import { formatDate } from "../../utils/formatDate";
import { blogPage } from "../../utils/URLs";

const HorizontalBlogCard = ({ blogData }) => {
  const navigate = useNavigate();
  const image = JSON.parse(blogData?.cover_image);

  return (
    <li
      className="blog-post-list-item"
      key={blogData?.id}
      onClick={() => navigate(`/${blogPage}/${blogData?.slug}`)}
    >
      <div className="blog-post-horizontal-card">
        <img
          className="blog-post-card-featured-image"
          src={image[0]?.url}
          alt="blog"
        />
        <div className="blog-post-card-body">
          <h3 className="blog-post-card-title">
            {blogData?.title.split(" ").slice(0, 12).join(" ")}
          </h3>
          <div className="blog-post-card-footer">
            <p className="blog-post-card-author">
              <a
                href={blogData?.redirect}
                target="_blank"
                rel="noopener noreferrer"
              >
                {blogData?.author}
              </a>
            </p>
            <p className="blog-post-card-date">
              {formatDate(blogData?.created_at)}
            </p>
          </div>
        </div>
      </div>
    </li>
  );
};

export default HorizontalBlogCard;
