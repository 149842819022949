import { createGlobalStyle } from "styled-components";

export const GlobalStyles = createGlobalStyle`
  body {
    background: ${({ theme }) => theme?.body};
    color: ${({ theme }) => theme?.text};
    padding: 0;
    margin: 0;
    transition: all 0.25s linear;
  }
  // .navbar-main{
  //   background: ${({ theme }) => theme?.body};
  //   border:1px solid ${({ theme }) => theme?.body};
  // }
  .header{
    background: ${({ theme }) => theme?.body};
    box-shadow:${({ theme }) => theme?.boxShadow};
  }
  .navbar-logo-fill{
    fill:${({ theme }) => theme?.navbarLogo}
  }
  .experience-box{
    background: ${({ theme }) => theme?.firstBox};
  }
  .estimation-box{
    background: ${({ theme }) => theme?.firstBox};
  }
  .projects-box{
    background: ${({ theme }) => theme?.firstBox};
  }
  .project-name-span{
    background: ${({ theme }) => theme?.projectBoxHeadingBorder};
  }
  .commercial-span{
    background: ${({ theme }) => theme?.projectBoxHeadingBorder};
  }
  .contact-input .input-box{
    background: ${({ theme }) => theme?.firstBox};
  }
  .projects-heading-main{
    -webkit-text-stroke:${({ theme }) => theme?.webkitStrokeText}
  }
  .bg-white{
    background-color:${({ theme }) => theme?.servicesBg}
  }
  .interior-img-container{
    background: ${({ theme }) => theme?.firstBox};
  }
  .circle-sm{
    background: ${({ theme }) => theme?.body};
  }
  .navbar-links .link-item{
    border-bottom:2px solid ${({ theme }) => theme?.body}
  }
  .navbar-links .services .hovering-nav {
    background: ${({ theme }) => theme?.firstBox};
  }
  .hovering-navbar {
    background: ${({ theme }) => theme?.firstBox};
  }

  .sub-dropdown {
    background: ${({ theme }) => theme?.firstBox};
  }
  .navbar-links-mobile{
    border: ${({ theme }) => theme?.menuBorder};
    background-color:${({ theme }) => theme?.body}
  }
  .side-drawer{
    background: ${({ theme }) => theme?.firstBox};
  }
  .ellipse{
    fill: ${({ theme }) => theme?.svgFill};
  }
  .loader-main{
    background: ${({ theme }) => theme?.body};
  }
  .mapFill{
    fill:${({ theme }) => theme?.mapFill};
  }
  .mapText{
    fill:${({ theme }) => theme?.mapText}
  }
  .minor-heading-2{
    background: ${({ theme }) => theme?.firstBox};
  }
  .minor-description
  .team-box{
    background: ${({ theme }) => theme?.firstBox};
  }
  .estimate-name-senior{
    background: ${({ theme }) => theme?.firstBox};
  }
  .senior-role{
    background: ${({ theme }) => theme?.firstBox};
  }
  .moving-bg{
    background-color: ${({ theme }) => theme?.movingBg};
  }
  .contact-input .input-box{
    color: ${({ theme }) => theme?.text};
  }
  .service-heading{
    background-color:${({ theme }) => theme?.serviceHeading};
  }
  .service-description{
    background-color:${({ theme }) => theme?.serviceDescription};
  }
  .contact-input .talk-btn{
    background-color:${({ theme }) => theme?.contactBtn};
    border:2px solid ${({ theme }) => theme?.contactBtn};
    color:${({ theme }) => theme?.contactBtnSvg}
  }
  .contact-input .talk-btn:hover{
    background-color:${({ theme }) => theme?.body};
    color:${({ theme }) => theme?.svgFill};
  }
  .contact-input .talk-btn .talk-btn-svg{
    stroke:${({ theme }) => theme?.contactBtnSvg}
  }
  .contact-input .talk-btn:hover .talk-btn-svg{
    stroke:${({ theme }) => theme?.svgFill};
  }
  .overlay-message{
    background-color:${({ theme }) => theme?.contactOverlay};
  }
  .interior-process-box{
    background-color:${({ theme }) => theme?.body};
  }
  .afterImage-bg{
    background: ${({ theme }) => theme?.body};
  }
  .bim-bg{
    background: ${({ theme }) => theme?.bimBg};
  }
`;
