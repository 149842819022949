import React, { useEffect, useState } from "react";
import Loader from "../../Common/Loader";
import Main from "./Main";

function About({ btoa, onToggle, show, handler, backdrop }) {
  //
  //
  const [loading, setLoading] = useState(false);
  const [teamData, setTeamData] = useState();

  useEffect(() => {
    document.title = "About | Dastkaar";
  }, []);

  const fetchTeamData = async () => {
    try {
      const res = await fetch(`${process.env.REACT_APP_BASE_URL}/member/`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Basic " + btoa,
        },
      });
      const response = await res.json();
      setTeamData(response);
    } catch (error) {
      console.log(console.error);
    }
  };

  const [gsapLoading, setGsapLoading] = useState(false);

  useEffect(() => {
    fetchTeamData();
  }, []);

  useEffect(() => {
    if (teamData !== undefined) {
      setLoading(false);
    } else {
      setLoading(true);
    }
  });
  return (
    <div data-lenis-prevent>
      {loading === true ? (
        <Loader />
      ) : (
        <Main
          onToggle={onToggle}
          show={show}
          handler={handler}
          backdrop={backdrop}
          teamData={teamData}
          gsapLoading={gsapLoading}
        />
      )}
    </div>
  );
}

export default About;
