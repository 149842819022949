import React, { useEffect, useState } from "react";
import Navbar from "../../Nav/Navbar";
import Hero from "./Hero";
import Projects from "../Home/Projects";
import Loader from "../../Common/Loader";
import Main from "./Main";
import { useParams } from "react-router-dom";

function ProjectPageMain({ btoa, onToggle, show, handler, backdrop }) {
  const [loading, setLoading] = useState();
  const { pathnameParam } = useParams();
  console.log("pathnameParam", pathnameParam);
  const [projectsData, setProjectsData] = useState();
  const [path, setPath] = useState("");
  let pathname = window.location.pathname.slice(9);

  useEffect(() => {
    document.title = "Projects | Dastkaar";
  }, []);
  useEffect(() => {
    setPath(window.location.pathname.slice(9));
  }, []);

  const fetchProjectsData = async () => {
    let path;
    setLoading(true);

    if (pathname === "Lumion") {
      path = "Lumion/D5";
    } else if (pathname === "Revit") {
      path = "Revit/AutoCAD";
    } else if (pathname === "costX") {
      path = "CostX";
    } else {
      path = pathname;
    }

    try {
      const data = await fetch(
        `${process.env.REACT_APP_BASE_URL}/project/?type=${path}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Basic ${btoa}`,
          },
        }
      );
      const response = await data.json();
      setProjectsData(response);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const [gsapLoading, setGsapLoading] = useState(false);

  useEffect(() => {
    if (loading === true) {
      setGsapLoading(true);
    }
  }, [loading]);
  // useEffect(() => {
  //   if (projectsData !== undefined) {
  //     setLoading(false);
  //   } else {
  //     setLoading(true);
  //   }
  // });

  useEffect(() => {
    fetchProjectsData();
  }, [pathname, btoa]);

  return (
    <div data-lenis-prevent>
      {loading === true ? (
        <Loader />
      ) : (
        <>
          <Main
            onToggle={onToggle}
            show={show}
            handler={handler}
            backdrop={backdrop}
            projectsData={projectsData}
            gsapLoading={gsapLoading}
          />
        </>
      )}
    </div>
  );
}

export default ProjectPageMain;
