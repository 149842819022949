import React, { useEffect, useRef, useState } from "react";

import CommericalProjects from "./CommericalProjects";
import ResidentialProjects from "./ResidentialProjects";

function Projects({ projectsData }) {
  return (
    <section
      className="section-padding-normal projects-bg-content"
      data-lenis-prevent
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-8 offset-lg-2">
            <p className="minor-description-2">See the best of the best.</p>
          </div>
        </div>
      </div>
      <ResidentialProjects projectsData={projectsData} />
      <CommericalProjects projectsData={projectsData} />
    </section>
  );
}

export default Projects;
