import DownloadPdfButton from "../../Common/DownloadPdfButton";
import EstimationCategories from "../../Common/EstimationCategories";
import EstimationContact from "../../Common/EstimationContact";
import ServicesList from "../../Common/ServicesList";
import { drywallData, steelData } from "../../Data/Data";

const Main = () => {
  return (
    <section className="section-padding-normal " data-lenis-prevent>
      <div className="container">
        <div className="row">
          <div className="col-lg-9">
            <h1 className="estimate-heading">
              PAINTING TAKEOFF & ESTIMATING SERVICES
            </h1>
            <p className="minor-description-3">
              At Dastkaar Studio, we offer comprehensive painting estimation and
              takeoff services to simplify the planning and execution of your
              painting projects. Our experienced team specializes in providing
              detailed and accurate estimates tailored to meet the unique needs
              of each project.
            </p>
            <h1 className="estimate-heading mt-50">
              Why We Standout in Drywall Takeoff Services:
            </h1>
            <p className="minor-description-3">
              Our painting estimation and takeoff services include:
            </p>
            <p className="minor-description-3">
              <span className="span-color">Material Quantification:</span> We
              quantify the amount of paint, primers, sealers, and other
              materials required based on the surface area to be painted and the
              type of paint chosen.
              <br />
              <span className="span-color">Labor Calculation:</span> We
              calculate the labor hours needed for surface preparation,
              painting, and finishing work, taking into account factors such as
              surface condition, complexity, and project size.
              <br />
              <span className="span-color">Equipment Assessment:</span> We
              assess the equipment and tools needed for the painting project,
              such as brushes, rollers, sprayers, scaffolding, and safety gear.
              <br />
              <span className="span-color">Detailed Breakdown: </span> Our
              estimates provide a detailed breakdown of costs for materials,
              labor, and equipment, ensuring transparency and accuracy in
              budgeting.
              <br />
              <span className="span-color">Customization:</span> We tailor our
              estimation services to suit the specific requirements of
              residential, commercial, or industrial painting projects.
              <br />
              <span className="span-color">Takeoff Accuracy: </span> Our
              meticulous takeoff process ensures that no aspect of the painting
              project is overlooked, resulting in precise estimates and
              efficient resource allocation.
              <br />
              <span className="span-color">Timely Delivery:</span> We prioritize
              timely delivery of estimates, enabling you to plan and budget
              effectively for your painting projects.
            </p>
            <div className="row">
              <ServicesList services={steelData} single={true} />
            </div>
            <div className="row">
              <div className="col-lg-6">
                <img
                  className="estimate-img"
                  src={process.env.PUBLIC_URL + "/images/painting-red.webp"}
                  alt="resdential"
                />
              </div>
              <div className="col-lg-6">
                <img
                  className="estimate-img"
                  src={process.env.PUBLIC_URL + "/images/paint-pdf.webp"}
                  alt="resdential"
                />
              </div>
            </div>
            <div className="download-button">
              <DownloadPdfButton url="Paint.pdf" />
            </div>
            <p className="minor-description-3">
              By partnering with Dastkaar Studio for your{" "}
              <span className="span-color">
                painting estimation and takeoff needs
              </span>
              , you can streamline the planning process, minimize uncertainties,
              and achieve successful outcomes for your painting projects. Let us
              handle the details so you can focus on bringing your vision to
              life. Contact us today to learn more about our painting estimation
              and takeoff services.
            </p>
          </div>
          <div className="col-lg-3">
            <EstimationCategories />
            <EstimationContact />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Main;
