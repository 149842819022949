import React, { useState } from "react";
import MovingBackground from "../../Common/MovingBackground";
import Form from "./Form";
import animationData from "../../Lottie/tea.json";
import Lottie from "react-lottie";
import { uploadFileToCloudinary } from '../../services/cloudinary';

function Contact() {
  const [isSuccess, setIsSuccess] = useState(false);
  const [message, setMessage] = useState(false);
  const [userEmail, setUserEmail] = useState("");
  const [userName, setUserName] = useState("");
  const [disableBtn, setDisableBtn] = useState(false);
  const [pdf, setPdf] = useState();
  const apiKey = process.env.REACT_APP_MAIL;
  const submitUrl = "https://api.web3forms.com/submit";

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const SubmitForm = async (e) => {
    e.preventDefault();
    setDisableBtn(true);
    let access_key = apiKey;
    let name = userName;
    let email = userEmail;
    const { url } = await uploadFileToCloudinary(pdf)
    // let file_url =
    //   "https://cdn.dastkaar.studio/website-images/images/contact-us.webp";
    const headers = {
      method: "POST",
      body: JSON.stringify({ name, email, message, access_key, file_url: url }),
      headers: new Headers({
        "Content-Type": "application/json",
      }),
    };
    const messagaeSuccess = await fetch(submitUrl, headers);
    const data = await messagaeSuccess.json();
    if (data.success === true) {
      setDisableBtn(false);
      setIsSuccess(true);
    }
  };

  return (
    <>
      {window.location.pathname === "/" && (
        <MovingBackground bg_name="contact-bg" heading="Contact" />
      )}
      <section className="section-padding-normal">
        {isSuccess === false ? (
          <div className="container position-relative">
            {disableBtn ? (
              <div className="row">
                <div className="overlay-message">
                  <div className="message-loader"></div>
                </div>
              </div>
            ) : (
              <></>
            )}

            <div className="row">
              <div className="col-lg-8 offset-lg-2">
                <p
                  className="minor-description-2"
                  style={{ marginBottom: "50px" }}
                >
                  Have a coffee with us.
                </p>
              </div>
            </div>
            <Form
              setUserName={setUserName}
              setUserEmail={setUserEmail}
              setMessage={setMessage}
              userName={userName}
              userEmail={userEmail}
              disableBtn={disableBtn}
              SubmitForm={SubmitForm}
              setDisableBtn={setDisableBtn}
              pdf={pdf}
              setPdf={setPdf}
            />
          </div>
        ) : (
          <div className="container">
            <div className="row">
              <div className="col-lg-6 offset-lg-3">
                <div className="message-box">
                  <p className="message-text">
                    Request for coffee received.
                    <br />
                    We will get back to you soon.
                  </p>
                  <Lottie options={defaultOptions} height={250} width={250} />
                </div>
              </div>
            </div>
          </div>
        )}
      </section>
    </>
  );
}

export default Contact;
