import React, { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import Toggle from "../DarkMode/Toggle";
import {
  aboutUs,
  bim,
  blogspage,
  commercialEstimates,
  concreteEstimates,
  contactUs,
  drywallEstimates,
  electricalEstimates,
  estimationPage,
  exteriorDesign,
  flooringEstimates,
  interiorDesign,
  lumberEstimates,
  masonryEstimates,
  paintingEstimates,
  projectsPage,
  resdentialEstimates,
  services,
  steelEstimates,
  walkthroughs,
} from "../utils/URLs";
import NavbarLogo from "./NavbarLogo";
import ToggleMenu from "./ToggleMenu";

function Navbar({ onToggle, handler }) {
  const [showSubDropdown, setShowSubDropdown] = useState(false);
  const [showCompleteMenu, setShowCompleteMenu] = useState(false);
  const [showSingleTradeMenu, setShowSingleTradeMenu] = useState(false);
  const [showSingleTradeOff, setShowSingleTradeOff] = useState(false);

  useEffect(() => {
    if (!showSubDropdown) {
      setShowCompleteMenu(false);
      setShowSingleTradeMenu(false);
      setShowSingleTradeOff(false);
    }
  }, [showSubDropdown]);

  console.log();

  return (
    <header className="header">
      <div className="container">
        <div className="row" style={{ marginLeft: "0px", marginRight: "0px" }}>
          <div className="navbar-main">
            <div>
              <NavLink to="/">
                <NavbarLogo />
              </NavLink>
            </div>
            <div className="navbar-links">
              <NavLink
                to="/"
                className={({ isActive }) =>
                  isActive ? "active-link-border link-item" : "link-item"
                }
              >
                Home
              </NavLink>

              <div className="link-item services">
                Projects
                <div className="hovering-nav">
                  <NavLink
                    to={`/${projectsPage}/Revit`}
                    className={({ isActive }) =>
                      isActive ? "active-link-border link-item" : "link-item"
                    }
                  >
                    Revit/AutoCAD
                  </NavLink>
                  <NavLink
                    to={`/${projectsPage}/Lumion`}
                    className={({ isActive }) =>
                      isActive ? "active-link-border link-item" : "link-item"
                    }
                  >
                    Lumion/D5
                  </NavLink>
                  <NavLink
                    to={`/${projectsPage}/SketchUp`}
                    className={({ isActive }) =>
                      isActive ? "active-link-border link-item" : "link-item"
                    }
                  >
                    SketchUp
                  </NavLink>
                  <NavLink
                    to={`/${projectsPage}/BlueBeam`}
                    className={({ isActive }) =>
                      isActive ? "active-link-border link-item" : "link-item"
                    }
                  >
                    BlueBeam
                  </NavLink>
                  <NavLink
                    to={`/${projectsPage}/costX`}
                    className={({ isActive }) =>
                      isActive ? "active-link-border link-item" : "link-item"
                    }
                  >
                    Cost X
                  </NavLink>
                  <NavLink
                    to={`/${projectsPage}/PlanSwift`}
                    className={({ isActive }) =>
                      isActive ? "active-link-border link-item" : "link-item"
                    }
                  >
                    PlanSwift
                  </NavLink>
                </div>
              </div>
              <div className="link-item services">
                Architecture
                <div className="hovering-nav">
                  <NavLink
                    to={`/${interiorDesign}`}
                    className={({ isActive }) =>
                      isActive ? "active-link-border link-item" : "link-item"
                    }
                  >
                    Interior Design
                  </NavLink>
                  <NavLink
                    to={`/${exteriorDesign}`}
                    className={({ isActive }) =>
                      isActive ? "active-link-border link-item" : "link-item"
                    }
                  >
                    Exterior Design
                  </NavLink>
                  <NavLink
                    to={`/${walkthroughs}`}
                    className={({ isActive }) =>
                      isActive ? "active-link-border link-item" : "link-item"
                    }
                  >
                    3d Visualisation
                  </NavLink>
                  <NavLink
                    to={`/${bim}`}
                    className={({ isActive }) =>
                      isActive ? "active-link-border link-item" : "link-item"
                    }
                  >
                    BIM
                  </NavLink>
                </div>
              </div>
              <div
                className="link-item services"
                onMouseEnter={() => setShowSubDropdown(true)}
                onMouseLeave={() => setShowSubDropdown(false)}
              >
                <NavLink
                  to={`/${estimationPage}`}
                  className={({ isActive }) =>
                    isActive ? "active-link-border link-item" : "link-item"
                  }
                >
                  Estimation
                </NavLink>
                <div className="hovering-navbar">
                  <div
                    className={({ isActive }) =>
                      isActive ? "active-link-border link-item" : "link-item"
                    }
                    onMouseOver={() => {
                      setShowSingleTradeMenu(false);
                      setShowCompleteMenu(true);
                      setShowSingleTradeOff(false);
                    }}
                  >
                    Complete Estimates
                  </div>
                  <div
                    className={({ isActive }) =>
                      isActive ? "active-link-border link-item " : "link-item"
                    }
                    onMouseOver={() => {
                      setShowCompleteMenu(false);
                      setShowSingleTradeMenu(true);
                      setShowSingleTradeOff(false);
                    }}
                  >
                    Single-Trade Estimates
                  </div>
                  <div
                    className={({ isActive }) =>
                      isActive ? "active-link-border link-item" : "link-item"
                    }
                    onMouseOver={() => {
                      setShowCompleteMenu(false);
                      setShowSingleTradeMenu(false);
                      setShowSingleTradeOff(true);
                    }}
                  >
                    Single-Trade Takeoffs
                  </div>

                  {/* Second dropdown */}
                  {showSubDropdown &&
                  (showCompleteMenu ||
                    showSingleTradeMenu ||
                    showSingleTradeOff) ? (
                    <div
                      className={`sub-dropdown ${
                        showSubDropdown ? "visible" : ""
                      }`}
                    >
                      {showCompleteMenu ? (
                        <>
                          <NavLink
                            to={`/${resdentialEstimates}`}
                            className={({ isActive }) =>
                              isActive
                                ? "active-link-border link-item"
                                : "link-item"
                            }
                          >
                            Residential Estimates
                          </NavLink>
                          <NavLink
                            to={`/${commercialEstimates}`}
                            className={({ isActive }) =>
                              isActive
                                ? "active-link-border link-item"
                                : "link-item"
                            }
                          >
                            Comercial Estimates
                          </NavLink>
                        </>
                      ) : (
                        <></>
                      )}
                      {showSingleTradeMenu ? (
                        <>
                          <NavLink
                            to={`/${concreteEstimates}`}
                            className={({ isActive }) =>
                              isActive
                                ? "active-link-border link-item"
                                : "link-item"
                            }
                          >
                            Concrete Estimates
                          </NavLink>
                          <NavLink
                            to={`/${masonryEstimates}`}
                            className={({ isActive }) =>
                              isActive
                                ? "active-link-border link-item"
                                : "link-item"
                            }
                          >
                            Masonry Estimates
                          </NavLink>
                          <NavLink
                            to={`/${drywallEstimates}`}
                            className={({ isActive }) =>
                              isActive
                                ? "active-link-border link-item"
                                : "link-item"
                            }
                          >
                            Drywall Estimates
                          </NavLink>
                          <NavLink
                            to={`/${electricalEstimates}`}
                            className={({ isActive }) =>
                              isActive
                                ? "active-link-border link-item"
                                : "link-item"
                            }
                          >
                            Electricall Estimates
                          </NavLink>
                        </>
                      ) : (
                        <></>
                      )}
                      {showSingleTradeOff ? (
                        <>
                          <NavLink
                            to={`/${lumberEstimates}`}
                            className={({ isActive }) =>
                              isActive
                                ? "active-link-border link-item"
                                : "link-item"
                            }
                          >
                            Lumber Estimates
                          </NavLink>
                          <NavLink
                            to={`/${steelEstimates}`}
                            className={({ isActive }) =>
                              isActive
                                ? "active-link-border link-item"
                                : "link-item"
                            }
                          >
                            Steel Estimates
                          </NavLink>
                          <NavLink
                            to={`/${flooringEstimates}`}
                            className={({ isActive }) =>
                              isActive
                                ? "active-link-border link-item"
                                : "link-item"
                            }
                          >
                            Flooring Estimates
                          </NavLink>
                          <NavLink
                            to={`/${paintingEstimates}`}
                            className={({ isActive }) =>
                              isActive
                                ? "active-link-border link-item"
                                : "link-item"
                            }
                          >
                            Painting Estimates
                          </NavLink>
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
              <NavLink
                to={`/${blogspage}`}
                className={({ isActive }) =>
                  isActive ? "active-link-border link-item" : "link-item"
                }
              >
                Blogs
              </NavLink>
              <NavLink
                to={`/${aboutUs}`}
                className={({ isActive }) =>
                  isActive ? "active-link-border link-item" : "link-item"
                }
              >
                About Us
              </NavLink>
              <NavLink
                to={`/${contactUs}`}
                className={({ isActive }) =>
                  isActive ? "active-link-border link-item" : "link-item"
                }
              >
                Contact
              </NavLink>
              <Toggle onToggle={onToggle}>Toggle</Toggle>
            </div>
            <ToggleMenu handler={handler} />
          </div>
        </div>
      </div>
    </header>
  );
}

export default Navbar;
