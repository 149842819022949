import React from "react";

function ToggleMenu({ handler }) {
  return (
    <div className="navbar-links-mobile" onClick={handler}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="14"
        height="12"
        viewBox="0 0 14 12"
      >
        <g
          id="Group_83"
          data-name="Group 83"
          transform="translate(-1196.138 -33.345)"
        >
          <circle
            id="Ellipse_10"
            className="ellipse"
            data-name="Ellipse 10"
            cx="2.5"
            cy="2.5"
            r="2.5"
            transform="translate(1196.138 33.345)"
          />
          <circle
            id="Ellipse_13"
            data-name="Ellipse 13"
            className="ellipse"
            cx="2.5"
            cy="2.5"
            r="2.5"
            transform="translate(1196.138 40.345)"
          />
          <circle
            id="Ellipse_11"
            data-name="Ellipse 11"
            className="ellipse"
            cx="2.5"
            cy="2.5"
            r="2.5"
            transform="translate(1205.138 33.345)"
          />
          <circle
            id="Ellipse_12"
            data-name="Ellipse 12"
            className="ellipse"
            cx="2.5"
            cy="2.5"
            r="2.5"
            transform="translate(1205.138 40.345)"
          />
        </g>
      </svg>
    </div>
  );
}

export default ToggleMenu;
