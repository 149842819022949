import React from "react";

function BackgroundImg({ bg }) {
  return (
    <>
      <section
        className="detail-background-img"
        style={{
          backgroundImage: `url(${bg})`,
        }}
      ></section>
    </>
  );
}

export default BackgroundImg;
