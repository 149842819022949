import React from "react";

function NavbarLogo() {
  return (
    <svg
      className="navbar-logo"
      xmlns="http://www.w3.org/2000/svg"
      width="216.35"
      height="216.6"
      viewBox="0 0 216.35 216.6"
    >
      <g id="Logo-71" transform="translate(-71.95 -43.38)">
        <path
          id="Path_1747"
          data-name="Path 1747"
          d="M206.82,233.08c-1.65,1.63-3.3,3.24-4.94,4.88l-20.67,20.67c-.31.31-.58.66-1.17,1.35a7.313,7.313,0,0,0-.8-1.29Q166.9,246.3,154.51,233.95c-.31-.31-.65-.59-.97-.88l-11.49-11.49c-.29-.32-.57-.66-.88-.97q-8.76-8.775-17.55-17.53a4.475,4.475,0,0,0-1-.61l8.4-8.4c.2.24.44.56.72.84q12.915,12.93,25.84,25.84c.31.31.64.59.96.89.69.73,1.39,1.45,1.91,2,.65-.5,1.2-.92,1.81-1.38-.32-.29-.55-.49-.78-.69-.27-.29-.52-.6-.8-.88q-13.635-13.65-27.28-27.28a10.25,10.25,0,0,0-.95-.75c2.82-2.81,5.49-5.48,8.27-8.26.21.25.48.6.78.9q14.31,14.325,28.64,28.63c2.63,2.62,5.34,5.14,8.02,7.71-.09.35-.06.82-.28,1.05q-2.88,2.97-5.84,5.86c-1.07,1.06-2.18,2.08-3.28,3.12.6.69,1.04,1.19,1.56,1.79,3.24-3.25,6.42-6.44,9.65-9.67,3.04,3.04,6.05,6.01,9.01,9.04.68.69,1.15.71,1.72-.04a4.9,4.9,0,0,1,.49-.53c-3.47-3.59-6.92-7.14-10.36-10.69-.29-.32-.57-.66-.88-.96q-18.48-18.5-36.98-36.96a2.481,2.481,0,0,0-.92-.46l28.27-28.27,8.37,8.37c-3.3,3.3-6.71,6.7-10.18,10.17.47.45.95.91,1.57,1.51,3.43-3.48,6.84-6.94,10.07-10.22l8.5,8.5-10.17,10.14c.5.52.99,1.03,1.6,1.67,3.49-3.49,6.92-6.93,10.6-10.61,2.58,2.84,5.15,5.67,7.47,8.22-5.78,6.02-11.79,12.29-17.88,18.63-6.8-6.71-13.38-13.2-20-19.73-.5.48-.95.92-1.45,1.4.3.33.53.6.78.84q18.615,18.615,37.23,37.22c.22.22.48.42.71.63q5.1,5.175,10.2,10.34c-.37.43-.65.75-.94,1.08ZM180.15,243.2c.64-.61,1.12-1.07,1.68-1.61-.57-.51-1.09-.98-1.6-1.43l-1.43,1.43C179.24,242.12,179.67,242.63,180.15,243.2Z"
          className="navbar-logo-fill"
        />
        <path
          id="Path_1748"
          data-name="Path 1748"
          d="M98.6,177.95c-.29-.27-.6-.52-.88-.8q-12.33-12.315-24.66-24.64a10.7,10.7,0,0,0-1.11-.84c.48-.5.76-.82,1.06-1.12q12.405-12.405,24.82-24.82c.28-.28.54-.59.81-.88q5.73-5.745,11.47-11.48a5.54,5.54,0,0,0,.45-.37q15.555-15.54,31.1-31.09a6.089,6.089,0,0,0,.46-.55l11.51-11.48q8.61-8.58,17.21-17.16c3.11-3.1,6.2-6.21,9.34-9.34q13.32,13.3,26.54,26.5l-16.3.06c-3.43-3.37-6.87-6.74-10.13-9.95-3.38,3.28-6.82,6.61-10.26,9.94L158.68,81.28c-.32.29-.66.57-.97.88q-23.445,23.43-46.88,46.88c-.28.28-.53.59-.8.89q-5.745,5.73-11.48,11.47c-3.33,3.42-6.66,6.83-9.85,10.1,3.25,3.34,6.55,6.75,9.86,10.16.04,2.42.12,4.84.12,7.25,0,3.01-.06,6.03-.09,9.04Z"
          className="navbar-logo-fill"
        />
        <path
          id="Path_1749"
          data-name="Path 1749"
          d="M206.82,233.08c.28-.33.57-.66.94-1.08q-5.1-5.175-10.2-10.34h4.2c-.2.28-.4.56-.45.62l8.33,8.33c2.82-2.94,5.75-5.98,8.68-9.03h32.01V173.23q0-15.285.03-30.58,0-6.36.04-12.71,5.685,5.67,11.36,11.35v20.39q-.03,7.74-.05,15.49a5.322,5.322,0,0,0,.12.8q0,26.91.01,53.81c0,.78.03,1.34-1.09,1.34q-26.955-.06-53.92-.04Z"
          fill="#fed348"
        />
        <path
          id="Path_1750"
          data-name="Path 1750"
          d="M98.6,177.95c.03-3.01.09-6.03.09-9.04,0-2.42-.08-4.84-.12-7.25q0-10.125-.01-20.25,5.745-5.73,11.48-11.47c.01.52.04,1.04.04,1.55q0,20.94.02,41.88c-.02,1.6-.06,3.19-.05,4.79.01,3.83.05,7.66.07,11.5-.01.16-.03.32-.03.48V220.7c0,.27.03.54.05.87a5.865,5.865,0,0,0,.64.06q15.645,0,31.28-.03l11.49,11.49q-11.205,0-22.41.03H98.6V177.97Z"
          fill="#fed348"
        />
        <path
          id="Path_1751"
          data-name="Path 1751"
          d="M158.68,81.28l11.35-11.35h20.39l16.3-.06q26.97,0,53.93-.04c1.13,0,1.19.5,1.19,1.37q-.03,26.85-.02,53.69-5.745-5.76-11.49-11.51V81.34H201.94q-20.8,0-41.6-.03c-.56,0-1.12-.03-1.67-.05Z"
          fill="#fed348"
        />
        <path
          id="Path_1752"
          data-name="Path 1752"
          d="M250.34,113.4q5.745,5.76,11.49,11.51c1.74,1.76,3.47,3.53,5.22,5.28q10.59,10.605,21.25,21.24-13.26,13.29-26.47,26.54a5.734,5.734,0,0,1-.12-.8q.015-7.74.05-15.49c3.36-3.45,6.72-6.9,9.9-10.17-3.24-3.34-6.57-6.78-9.9-10.21q-5.685-5.67-11.36-11.35c-.41-.44-.8-.89-1.22-1.31q-22.905-22.905-45.82-45.82c-.48-.48-.94-.97-1.41-1.46h16.34c.15.18.29.37.46.54q15.525,15.525,31.04,31.04c.17.17.36.3.54.45Z"
          className="navbar-logo-fill"
        />
        <path
          id="Path_1753"
          data-name="Path 1753"
          d="M110.12,113.37q-5.73,5.745-11.47,11.48c-.01-.48-.04-.96-.04-1.44q0-26.01-.03-52.01c0-1.16.21-1.57,1.49-1.57q26.79.075,53.57.05L142.13,81.36H110.17a6.043,6.043,0,0,0-.08.61q0,15.705.02,31.4Z"
          fill="#fed348"
        />
        <path
          id="Path_1763"
          data-name="Path 1763"
          d="M178.18,221.65c.89-.02,1.78-.05,2.68-.07l10.36,10.69a4.9,4.9,0,0,0-.49.53c-.57.75-1.04.73-1.72.04-2.96-3.03-5.97-6-9.01-9.04-3.23,3.24-6.41,6.43-9.65,9.67-.52-.6-.96-1.1-1.56-1.79,1.1-1.04,2.2-2.07,3.28-3.12,1.96-1.94,3.92-3.88,5.84-5.86.22-.23.19-.69.28-1.05Z"
          fill="#fbd147"
        />
        <path
          id="Path_1765"
          data-name="Path 1765"
          d="M158.55,221.64c.98-.02,1.96-.05,2.94-.07.23.2.46.41.78.69-.61.46-1.15.88-1.81,1.38C159.94,223.09,159.24,222.36,158.55,221.64Z"
          fill="#f6cd46"
        />
        <path
          id="Path_1769"
          data-name="Path 1769"
          d="M201.77,221.66c.38-.35.77-.68,1.14-1.05l46.64-46.64c.25-.25.52-.49.78-.74v16.34a5.951,5.951,0,0,0-.54.46q-15.54,15.54-31.09,31.1a5.194,5.194,0,0,0-.37.45c-2.93,3.04-5.86,6.09-8.68,9.03l-8.33-8.33c.05-.07.25-.34.45-.62Z"
          className="navbar-logo-fill"
        />
        <path
          id="Path_1772"
          data-name="Path 1772"
          d="M110.1,173.36l9.7,9.61c.4.39.77.81,1.11,1.18q9.72-9.72,19.25-19.26c2.85,2.85,5.52,5.53,8.1,8.1l-27.59,27.59c-3.41-3.54-6.98-7.24-10.54-10.94-.03-3.83-.06-7.66-.07-11.5,0-1.6.03-3.19.05-4.79Z"
          className="navbar-logo-fill"
        />
        <path
          id="Path_1779"
          data-name="Path 1779"
          d="M217.87,202.25l-18.28,18.28-8-7.97q5.025-4.98,10.26-10.19c-.62-.54-1.15-1-1.68-1.45L190.02,211.1c-2.8-2.81-5.48-5.48-8.09-8.1l18.34-18.34,17.59,17.59Z"
          className="navbar-logo-fill"
        />
        <path
          id="Path_1780"
          data-name="Path 1780"
          d="M154.21,159l.12-6.69v-1.62h8.35v8.28h-8.49l.03.03Z"
          className="navbar-logo-fill"
        />
        <path
          id="Path_1781"
          data-name="Path 1781"
          d="M154.18,158.98v8.47h-8.26v-8.44h8.29Z"
          className="navbar-logo-fill"
        />
      </g>
    </svg>
  );
}

export default NavbarLogo;
