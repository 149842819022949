import React, { useEffect, useState } from "react";
import gsap from "gsap";
import Navbar from "../../Nav/Navbar";
import Banner from "./Banner";
import Process from "../InteriorDesign/Process";
import { exteriorDesign } from "../../Data/Data";
import Sidedrawer from "../../Nav/Sidedrawer";
import Loader from "../../Common/Loader";
import Footer from "../../Common/Footer/Footer";

function ExteriorDesign({ onToggle, handler, show, backdrop }) {
  const [display, setDisplay] = useState(false);
  useEffect(() => {
    document.title = "Exterior Design | Dastkaar";
  }, []);
  useEffect(() => {
    setInterval(() => {
      setDisplay(true);
    }, 1500);
  }, []);
  return (
    <div data-lenis-prevent>
      {display === false ? (
        <Loader />
      ) : (
        <>
          <Navbar onToggle={onToggle} handler={handler} />
          <Sidedrawer onToggle={onToggle} show={show} handler={handler} />
          {backdrop}
          <main>
            <Banner />
            <Process props={exteriorDesign} />
          </main>
          <Footer />
        </>
      )}
    </div>
  );
}

export default ExteriorDesign;
