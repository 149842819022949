import React, { useEffect, useState } from "react";
import gsap from "gsap";
import Footer from "../../Common/Footer/Footer";
import Navbar from "../../Nav/Navbar";
import Sidedrawer from "../../Nav/Sidedrawer";
import Loader from "../../Common/Loader";
import { formatDate } from "../../utils/formatDate";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useNavigate } from "react-router-dom";
import { blogspage } from "../../utils/URLs";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  InstagramShareButton,
} from "react-share";
import { FacebookIcon, LinkedinIcon, TwitterIcon } from "react-share";
import { BlogSwiper } from "./BlogSwiper";

const BlogDetail = ({ btoa, onToggle, show, handler, backdrop }) => {
  const blogId = window.location.pathname.slice(6);
  const [blogData, setBlogData] = useState();
  const navigate = useNavigate();
  const currentUrl = window.location.href;

  const fetchBlogData = async () => {
    try {
      const res = await fetch(
        `${process.env.REACT_APP_BASE_URL}/blog/${blogId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic " + btoa,
          },
        }
      );
      const response = await res.json();
      setBlogData(response);
      setDisplay(false);
    } catch (error) {
      console.error(error);
      navigate(`${blogspage}`);
      setDisplay(true);
    }
  };

  useEffect(() => {
    fetchBlogData();
  }, []);

  useEffect(() => {
    document.title = "Blogs | Dastkaar";
  }, []);
  console.log(blogData);

  useEffect(() => {
    let tl = gsap.timeline({
      defaults: { ease: "Power3.easeInOut", duration: 1 },
    });
    tl.to(".contact-heading", {
      clipPath: "polygon(100% 0, 0 0, 0 100%, 100% 100%)",
      opacity: 1,
      x: 0,
      duration: 1.2,
    });
  });
  const [display, setDisplay] = useState(true);
  var Size = Quill.import("attributors/style/size");
  const Font = Quill.import("formats/font");
  // We do not add Aref Ruqaa since it is the default
  Font.whitelist = ["reddit", "roboto", "merriweather"];
  Quill.register(Font, true);
  Size.whitelist = [
    "9px",
    "10px",
    "11px",
    "12px",
    "14px",
    "16px",
    "18px",
    "20px",
    "22px",
    "24px",
    "26px",
    "28px",
  ];
  Quill.register(Size, true);
  let image;
  if (blogData?.cover_image) {
    image = JSON.parse(blogData?.cover_image);
  }
  return (
    <div data-lenis-prevent>
      {display ? (
        <Loader />
      ) : (
        <>
          <Navbar onToggle={onToggle} handler={handler} />
          <Sidedrawer onToggle={onToggle} show={show} handler={handler} />
          {backdrop}
          <main>
            <section className="section-padding-top">
              <div className="container">
                <div className="row flex-center">
                  <div className="col-lg-10 offset-lg-1">
                    <h2 className="blog-title">{blogData?.title}</h2>
                    <div className="blog-name-wrapper">
                      <div className="avatar-name">
                        <div className="avatar-wrapper">
                          <svg
                            viewBox="64 64 896 896"
                            focusable="false"
                            data-icon="user"
                            width="25px"
                            height="25px"
                            fill="currentColor"
                            aria-hidden="true"
                          >
                            <path d="M858.5 763.6a374 374 0 00-80.6-119.5 375.63 375.63 0 00-119.5-80.6c-.4-.2-.8-.3-1.2-.5C719.5 518 760 444.7 760 362c0-137-111-248-248-248S264 225 264 362c0 82.7 40.5 156 102.8 201.1-.4.2-.8.3-1.2.5-44.8 18.9-85 46-119.5 80.6a375.63 375.63 0 00-80.6 119.5A371.7 371.7 0 00136 901.8a8 8 0 008 8.2h60c4.4 0 7.9-3.5 8-7.8 2-77.2 33-149.5 87.8-204.3 56.7-56.7 132-87.9 212.2-87.9s155.5 31.2 212.2 87.9C779 752.7 810 825 812 902.2c.1 4.4 3.6 7.8 8 7.8h60a8 8 0 008-8.2c-1-47.8-10.9-94.3-29.5-138.2zM512 534c-45.9 0-89.1-17.9-121.6-50.4S340 407.9 340 362c0-45.9 17.9-89.1 50.4-121.6S466.1 190 512 190s89.1 17.9 121.6 50.4S684 316.1 684 362c0 45.9-17.9 89.1-50.4 121.6S557.9 534 512 534z"></path>
                          </svg>
                        </div>
                        <h2 className="blog-author">
                          <a
                            href={blogData?.redirect}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {blogData?.author}
                          </a>
                        </h2>
                      </div>
                      <div>
                        <h3>{formatDate(blogData?.created_at)}</h3>
                        <h3>
                          <span className="span-color">
                            {blogData?.time}{" "}
                            {Number(blogData?.time) > 10 ? "minutes" : "minute"}
                          </span>{" "}
                          read
                        </h3>
                      </div>
                    </div>
                    <div className="col-lg-8 offset-lg-2">
                      <ReactQuill
                        theme="bubble"
                        value={blogData?.description}
                        readOnly={true}
                        modules={{
                          toolbar: false,
                        }}
                      />
                      {/* <p className="seo-description">{blogData?.description}</p> */}
                      <img
                        className="blog-detail-img"
                        width="100%"
                        src={blogData?.cover_image && image[0]?.url}
                        alt="blog"
                      />
                      <ReactQuill
                        theme="bubble"
                        value={blogData?.blog}
                        className="text-editer"
                        readOnly={true}
                        modules={{
                          toolbar: false,
                        }}
                      />
                    </div>
                    {/* <div className="social-share-buttons">
                      <FacebookShareButton
                        url={currentUrl}
                        quote={blogData?.title}
                      >
                        <FacebookIcon size={32} round />
                      </FacebookShareButton>
                      <TwitterShareButton
                        url={currentUrl}
                        title={blogData?.title}
                      >
                        <TwitterIcon size={32} round />
                      </TwitterShareButton>
                      <LinkedinShareButton
                        url={currentUrl}
                        summary={blogData?.description}
                        title={blogData?.title}
                      >
                        <LinkedinIcon size={32} round />
                      </LinkedinShareButton>
                    </div> */}
                  </div>
                  {/* <div>
                    <h1 className="more-like-this">Related Articles</h1>
                    <div style={{ width: "100%" }}>
                      <BlogSwiper blogData={blogData} />
                    </div>
                  </div> */}
                </div>
              </div>
            </section>
          </main>
          <Footer />
        </>
      )}
    </div>
  );
};

export default BlogDetail;
