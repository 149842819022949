import React from "react";
import Tiles from "./Tiles";

function BannerSection({
  name,
  year,
  location,
  description,
  firstPicture,
  clientName,
  area,
}) {
  return (
    <section
      className="project-detail-trigger-item"
      style={{ paddingTop: "80px" }}
    >
      <div className="container">
        <div className="row project-detail-banner">
          <div className="col-lg-6" style={{ padding: "0px" }}>
            <h1 className="project-detail-heading">{name}</h1>
            <p className="project-detail-paragraph">{description}</p>
            <div className="row project-detail-margin">
              <Tiles tileName="Client" tileDescription={clientName} />
              <Tiles tileName="Area" tileDescription={area} />
            </div>
            <div className="row project-detail-margin-2">
              <Tiles tileName="Year" tileDescription={year?.slice(0, 4)} />
              <Tiles tileName="Location" tileDescription={location} />
            </div>
          </div>
          <div className="col-lg-6 project-detail-hero">
            <img src={firstPicture} alt="" />
          </div>
        </div>
      </div>
    </section>
  );
}

export default BannerSection;
