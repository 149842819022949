import DownloadPdfButton from "../../Common/DownloadPdfButton";
import EstimationCategories from "../../Common/EstimationCategories";
import EstimationContact from "../../Common/EstimationContact";
import ServicesList from "../../Common/ServicesList";
import { masonryData } from "../../Data/Data";

const Main = () => {
  return (
    <section className="section-padding-normal " data-lenis-prevent>
      <div className="container">
        <div className="row">
          <div className="col-lg-9">
            <h1 className="estimate-heading">
              MASONRY TAKEOFF & ESTIMATING SERVICES
            </h1>
            <p className="minor-description-3">
              Struggling with completing your masonry estimates? Dastkaar Studio
              offers invaluable support to streamline your masonry estimating
              process. Our team of seasoned masonry cost estimators brings
              extensive experience from large-scale construction projects
              worldwide. Dastkaar Studio has successfully delivered estimates
              for major commercial and residential projects across various
              cities and countries, including Melbourne, Australia; Canada; the
              United States; and the United Kingdom.
            </p>
            <h1 className="estimate-heading mt-50">
              Why We Standout in Masonry Takeoff Services:
            </h1>
            <p className="minor-description-3">
              Relying on online masonry estimating spreadsheets or programs can
              be daunting, especially for newcomers to the estimation field.
              Entrusting such tools entails uncertainty about result accuracy
              and consumes significant time and effort. Why not allocate your
              resources more effectively?
              <br />
              Our dedicated team of professional masonry estimators at Dastkaar
              Studio understands your project's unique needs and takeoff
              requirements. We provide detailed{" "}
              <span className="span-color">masonry takeoffs</span>, empowering
              you to grasp your project's break-even point in upcoming tenders
              and make informed decisions.
              <br />
              Upload your plans and let us handle the rest.
            </p>
            <h1 className="estimate-heading  mt-50">
              What We Offer in Drywall Takeoff Services:
            </h1>
            <p className="minor-description-3">
              Dastkaar Studio offers quantification and preparation of masonry
              cost estimates across all subdivisions of{" "}
              <span className="span-color">
                Division 04 as per the CSI Master Format
              </span>
              . Our services encompass the following major items:
            </p>
            <div className="row">
              <ServicesList services={masonryData} />
            </div>
            <div className="row">
              <div className="col-lg-6">
                <img
                  className="estimate-img"
                  src={process.env.PUBLIC_URL + "/images/masonry.webp"}
                  alt="resdential"
                />
              </div>
              <div className="col-lg-6">
                <img
                  className="estimate-img"
                  src={process.env.PUBLIC_URL + "/images/masonry-pdf.webp"}
                  alt="resdential"
                />
              </div>
            </div>
            <div className="download-button">
              <DownloadPdfButton url="Masonry.pdf" />
            </div>
            <p className="minor-description-3">
              Trust <span className="span-color">Dastkaar Studio </span>
              to provide you with accurate and thorough estimates for all your
              masonry needs, ensuring your project is meticulously planned and
              executed to perfection.
            </p>
          </div>
          <div className="col-lg-3">
            <EstimationCategories />
            <EstimationContact />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Main;
