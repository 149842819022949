import React from "react";

function ProcessLines({ actionClass }) {
  return (
    <div className="row">
      <div className="col-lg-8 offset-lg-2" style={{ position: "relative" }}>
        <div className="process-lines-height">
          <div className={`circle-sm left-line-circle ${actionClass}`}></div>
          <div className={`circle-sm right-line-circle ${actionClass}`}></div>
          <div className="straight-line left-line"></div>
          <div className="straight-line right-line"></div>
        </div>
      </div>
    </div>
  );
}

export default ProcessLines;
