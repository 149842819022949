import React from "react";

function ClientReview({ name, location, feedback }) {
  return (
    <section className="section-padding-normal client-section">
      <div className="container">
        <div className="row" style={{ position: "relative" }}>
          <div className="col-lg-2">
            <div className="client-quote">
              <img
                className="quote-left"
                src={process.env.PUBLIC_URL + "/images/quotes-left.svg"}
                alt=""
              />
            </div>
          </div>
          <div className="col-lg-8">
            <p className="client-review">{feedback}</p>
          </div>
          <div className="col-lg-2">
            <div className="client-quote">
              <img
                className="quote-right"
                src={process.env.PUBLIC_URL + "/images/quotes-right.svg"}
                alt=""
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-4 offset-lg-4">
            <div>
              <h3 className="client-name">{name}</h3>
            </div>
            <h2 className="client-country"> {location} </h2>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ClientReview;
