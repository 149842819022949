import React, { useEffect } from "react";
import gsap from "gsap";
function Hero() {
  return (
    <section
      className="project-page-bg align-items-center project-trigger-item"
      id="module"
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-8 offset-lg-2">
            <h1 className="projects-hero-heading projects-heading-main residential-heading">
              Masonry <br />
              Estimating <br />
              Services<span className="span-color ">.</span>
            </h1>
            <div className="svg-img">
              {/* <svg
                id="project-stroke"
                xmlns="http://www.w3.org/2000/svg"
                width="655.283"
                height="252.146"
                viewBox="0 0 655.283 252.146"
              >
                <path
                  id="Path_1457"
                  data-name="Path 1457"
                  d="M18902.814,9541.726s-153.9-86.985-8.129-179.28,414.215-54.011,500.8-21.781,126.879,110.482-27.693,180.707-544.713-28.525-544.713-28.525"
                  transform="translate(-18821.85 -9299.652)"
                  fill="none"
                  stroke="#e8ae1b"
                  strokeLinecap="round"
                  strokeWidth="10"
                />
              </svg> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Hero;
