import gsap from "gsap";
import React, { useEffect } from "react";
import { ScrollTrigger } from "gsap/ScrollTrigger";

function Circles({ firstColor, secondColor, thirdColor, needAnimation }) {
  gsap.registerPlugin(ScrollTrigger);
  useEffect(() => {
    let tl = gsap.timeline({
      defaults: { ease: "Power3.easeInOut", duration: 1 },
      scrollTrigger: {
        trigger: ".projects-bg-content",
        toggleActions: "play none none none",
        once: true,
      },
    });
    tl.to(".project-circle-box", {
      opacity: 1,
      transform: "translateY(0px)",
      stagger: 0.2,
      duration: 1.2,
    });
  });
  return (
    <div className="project-circle-box">
      <div
        className="project-circle"
        style={{
          backgroundColor: firstColor,
          zIndex: 4,
        }}
      ></div>
      <div
        className="project-circle"
        style={{
          backgroundColor: secondColor,
          zIndex: 3,
        }}
      ></div>
      <div
        className="project-circle"
        style={{
          backgroundColor: thirdColor,
          zIndex: 2,
        }}
      ></div>
    </div>
  );
}

export default Circles;
