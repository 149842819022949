import React from "react";

function MiddleSection({ middleImage }) {
  return (
    <div className="container">
      <div className="row project-description-section">
        <div className="col-lg-12">
          <img className="pd-description-img" src={middleImage} alt="" />
        </div>
      </div>
    </div>
  );
}

export default MiddleSection;
