import { useEffect, useState } from "react";
import Navbar from "../../Nav/Navbar";
import Loader from "../../Common/Loader";
import Sidedrawer from "../../Nav/Sidedrawer";
import Hero from "./Hero";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import Main from "./Main";
import Footer from "../../Common/Footer/Footer";

const ElectricalEstimates = ({ onToggle, handler, show, backdrop }) => {
  gsap.registerPlugin(ScrollTrigger);
  const [display, setDisplay] = useState(false);
  useEffect(() => {
    document.title = "Electric Estimates | Dastkaar";
  }, []);
  useEffect(() => {
    setInterval(() => {
      setDisplay(true);
    }, 1500);
  }, []);

  let estimates_page = gsap.timeline({
    defaults: { ease: "Power3.easeInOut", duration: 1 },
  });
  estimates_page.to(".projects-hero-heading", {
    clipPath: "polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)",
    // x: 0,
    // color: "#1C1B1B",
    duration: 2.2,
  });
  // ProjectsImage;
  let projects_page_Image = gsap.timeline({
    defaults: { ease: "Circ.easeOut" },
  });
  projects_page_Image.to(".svg-img", {
    opacity: 1,
    duration: 2.8,
  });

  return (
    <div>
      {display === false ? (
        <Loader />
      ) : (
        <>
          <Navbar onToggle={onToggle} handler={handler} />
          <Sidedrawer onToggle={onToggle} show={show} handler={handler} />
          {backdrop}
          <main>
            <Hero />
            <Main />
          </main>
          <Footer />
        </>
      )}
    </div>
  );
};
export default ElectricalEstimates;
