import DownloadPdfButton from "../../Common/DownloadPdfButton";
import EstimationCategories from "../../Common/EstimationCategories";
import EstimationContact from "../../Common/EstimationContact";
import ServicesList from "../../Common/ServicesList";
import { resdentialData } from "../../Data/Data";

const Main = () => {
  return (
    <section className="section-padding-normal " data-lenis-prevent>
      <div className="container">
        <div className="row">
          <div className="col-lg-9">
            <h1 className="project-name">
              EXPERT RESIDENTIAL COST ESTIMATIONS
            </h1>
            <p className="minor-description-3">
              Ensuring precise cost projections for projects is crucial for the
              prosperity of any construction enterprise. Residential projects
              frequently encounter minor errors or oversights in estimates,
              leading to significant overspending, sometimes amounting to
              hundreds of thousands or even millions of dollars beyond the
              initial budget. This underscores the importance of partnering with
              a seasoned and precise residential estimating service provider,
              such as<span className="span-color"> Dastkaar Studio</span>. Our
              expertise and precision not only save you valuable time and
              resources but also empower you to competitively bid on a wider
              array of residential projects, enhancing your business
              opportunities and profitability.
            </p>
            <h1 className="project-name mt-50">
              Why We Standout in Residential Estimation Services:
            </h1>
            <p className="minor-description-3">
              As specialists in preliminary and pre-construction final takeoffs,
              Dastkaar Studio delivers precise estimations for material
              quantities across various types of residential buildings. Our
              dedicated team meticulously analyzes your{" "}
              <span className="span-color">
                blueprints, scaling and measuring{" "}
              </span>
              every aspect of your project to ensure accuracy. All materials are
              itemized and detailed in an Excel spreadsheet, providing you with
              a comprehensive overview of your project before commencement,
              empowering you to make informed decisions with confidence.
            </p>
            <h1 className="project-name mt-50">
              What We Offer in Residential Estimation Services:
            </h1>
            <p className="minor-description-3">
              As residential cost estimators specializing in comprehensive
              material takeoffs, Dastkaar Studio caters to contractors,
              subcontractors, developers, architects, and more. Our services are
              designed to enhance your bidding success by providing precise
              residential takeoffs at an exceptionally reasonable cost. Backed
              by years of experience and deep industry knowledge, we recognize
              that detailed activity descriptions and accurate material
              quantities are fundamental to the success of any residential
              construction endeavor.
            </p>
            <p className="minor-description-3">
              Join the ranks of our satisfied clientele worldwide who have
              benefitted from our residential takeoff services, and seize the
              opportunity to elevate your business to new heights. Gain a
              thorough understanding of the materials required for your
              residential construction project today and pave the way for a
              profitable future with Dastkaar Studio.
            </p>
            <p className="minor-description-3">
              We provide material takeoffs for these kinds of residential
              buildings:
            </p>
            <ServicesList services={resdentialData} single={true} />
            <div className="row">
              <div className="col-lg-6">
                <img
                  className="estimate-img"
                  src={process.env.PUBLIC_URL + "/images/residential.webp"}
                  alt="resdential"
                />
              </div>
              <div className="col-lg-6">
                <img
                  className="estimate-img"
                  src={process.env.PUBLIC_URL + "/images/residential-pdf.webp"}
                  alt="resdential"
                />
              </div>
            </div>
            <div className="download-button">
              <DownloadPdfButton url="GCSample.pdf" />
            </div>
            <p className="minor-description-3">
              With our expertise and attention to detail, we ensure accurate and
              comprehensive estimates that meet the unique requirements of each
              project. Partner with{" "}
              <span className="span-color">Dastkaar Studio</span> for reliable
              estimating services that drive the success of your construction
              endeavors.
            </p>
          </div>
          <div className="col-lg-3">
            <EstimationCategories />
            <EstimationContact />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Main;
