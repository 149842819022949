import React from "react";
import FooterLink from "./FooterLink";

function Footer() {
  return (
    <footer className="footer-main">
      <section className="section-padding-top footer-bg">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <div style={{ display: "flex" }}>
                <h2 className="footer-heading">
                  We'd love to work with
                  <span className="span-color"> you</span>!
                </h2>
                <div>
                  <img
                    className="heart-1"
                    src={process.env.PUBLIC_URL + "/images/heart.svg"}
                    alt="heart"
                    height="70px"
                    width="70px"
                  />
                  <img
                    className="heart-2"
                    src={process.env.PUBLIC_URL + "/images/heart.svg"}
                    alt="heart"
                    height="70px"
                    width="70px"
                  />
                </div>
              </div>
              <p className="footer-description">
                {/* Through a unique combination of engineering, construction and
                design disciplines and expertise. */}
                Integrating Engineering, Design, and Precise Estimation
                Expertise for Exceptional Solutions
              </p>
            </div>
            <div className="col-lg-2">
              <FooterLink headingName="Company" />
            </div>
            <div className="col-lg-2">
              <FooterLink headingName="Follow Us" />
            </div>
          </div>
          <div className="row">
            <div className="col-lg-3">
              {/* <h6 className="footer-links-heading">Our Address</h6>
              <p className="footer-sm-links" style={{ marginBottom: "30px" }}>
                2741 Darby brooke ct ,<br /> Woodbridge,VA 22192
              </p> */}
              <h6 className="footer-links-heading">Call our office</h6>
              🇺🇸{" "}
              <a href="tel:+12762261864" className="footer-sm-links">
                +1 276 226 1864
              </a>
            </div>
            <div className="col-lg-3">
              <h6 className="footer-links-heading">Message us</h6>
              <a href="mailTo:info@dastkaar.studio">
                <p className="footer-sm-links">info@dastkaar.studio</p>
              </a>
            </div>
          </div>
          {/* <div className="row">
            <div className="col-lg-12">
              <div className="trademark">
                <p className="sinecure">
                  Designed & Developed by{" "}
                  <a
                    className="sinecure-text"
                    href="https://sinecure.xyz"
                    target="_blank"
                  >
                    Sinecure 🚀
                  </a>
                </p>
              </div>
            </div>
          </div> */}
        </div>
      </section>
    </footer>
  );
}

export default Footer;
