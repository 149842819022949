import { NavLink } from "react-router-dom";
import NavbarLogo from "../Nav/NavbarLogo";
import {
  commercialEstimates,
  concreteEstimates,
  drywallEstimates,
  electricalEstimates,
  flooringEstimates,
  lumberEstimates,
  masonryEstimates,
  paintingEstimates,
  resdentialEstimates,
  steelEstimates,
} from "../utils/URLs";

const Data = [
  {
    name: "Residential Estimates",
    link: resdentialEstimates,
  },
  {
    name: "Commercial Estimates",
    link: commercialEstimates,
  },
  {
    name: "Drywall Estimates",
    link: drywallEstimates,
  },
  {
    name: "Masonry Estimates",
    link: masonryEstimates,
  },
  {
    name: "Steel Estimates",
    link: steelEstimates,
  },
  {
    name: "Lumber Estimates",
    link: lumberEstimates,
  },
  {
    name: "Flooring Estimates",
    link: flooringEstimates,
  },
  {
    name: "Concrete Estimates",
    link: concreteEstimates,
  },
  {
    name: "Electrical Estimates",
    link: electricalEstimates,
  },
  {
    name: "Painting Estimates",
    link: paintingEstimates,
  },
];

const EstimationCategories = () => {
  return (
    <>
      <div className="catgories-main">
        <div className="category-logo">
          <NavbarLogo />
          <h2 className="minor-description-2 ">Dastkar Studio</h2>
        </div>
        <div className="catgeory-list">
          {Data.map((data, index) => {
            return (
              <>
                <NavLink to={`/${data.link}`}>
                  <div className="catgeory-box" key={index}>
                    {data.name}
                  </div>
                </NavLink>
              </>
            );
          })}
        </div>
      </div>
    </>
  );
};
export default EstimationCategories;
