import DownloadPdfButton from "../../Common/DownloadPdfButton";
import EstimationCategories from "../../Common/EstimationCategories";
import EstimationContact from "../../Common/EstimationContact";
import ServicesList from "../../Common/ServicesList";
import { commercialData } from "../../Data/Data";

const Main = () => {
  return (
    <section className="section-padding-normal " data-lenis-prevent>
      <div className="container">
        <div className="row">
          <div className="col-lg-9">
            <h1 className="estimate-heading">
              COMMERCIAL CONSTRUCTION ESTIMATION SERIVCES
            </h1>
            <p className="minor-description-3">
              Commercial estimating demands precision and expertise to avoid
              costly mistakes. Our professional estimators possess the necessary
              knowledge and experience to ensure accuracy, saving you both time
              and money. Whether it's new{" "}
              <span className="span-color">
                construction, renovation, addition, or restoration
              </span>
              , our quantity surveyors meticulously list every material required
              for your project.
            </p>
            <h1 className="estimate-heading mt-50">
              What Makes Us Standout in Commercial Estimating Services:
            </h1>
            <p className="minor-description-3">
              At Dastkaar Studio, we pride ourselves on our team of expert
              commercial construction estimators, dedicated to providing
              unparalleled estimating services for your commercial projects.
              With a keen understanding of the complexities of commercial
              construction, we offer detailed material takeoffs and cost
              analysis tailored to your project requirements.
            </p>
            <p className="minor-description-3">
              Furthermore, we prioritize{" "}
              <span className="span-color">quality control and assurance</span>
              throughout our process. Each project undergoes rigorous review by
              our senior managers, who bring invaluable construction field
              experience to the table. With Dastkaar Studio, you can trust that
              your commercial estimating needs are in the hands of seasoned
              professionals committed to delivering excellence.
            </p>
            <h1 className="estimate-heading  mt-50">
              We provide commercial estimating service for the construction of
              following projects;
            </h1>
            <div className="row">
              <ServicesList services={commercialData} />
            </div>
            <div className="row mt-20">
              <div className="col-lg-6">
                <img
                  className="estimate-img"
                  src={process.env.PUBLIC_URL + "/images/commercial.webp"}
                  alt="resdential"
                />
              </div>
              <div className="col-lg-6">
                <img
                  className="estimate-img"
                  src={process.env.PUBLIC_URL + "/images/residential-pdf.webp"}
                  alt="resdential"
                />
              </div>
            </div>
            <div className="download-button">
              <DownloadPdfButton url="GCSample.pdf" />
            </div>
            <p className="minor-description-3">
              With our expertise and attention to detail, we ensure accurate and
              comprehensive estimates that meet the unique requirements of each
              project. Partner with{" "}
              <span className="span-color">Dastkaar Studio</span> for reliable
              estimating services that drive the success of your construction
              endeavors.
            </p>
          </div>
          <div className="col-lg-3">
            <EstimationCategories />
            <EstimationContact />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Main;
