export const lightTheme = {
  name: "light",
  text: "#1c1b1b",
  body: "#fff",
  navbarLogo: "#1F222C",
  firstBox: "#fff",
  projectBoxHeadingBorder: "black",
  webkitStrokeText: "1px #1c1b1b",
  servicesBg: "white !important",
  menuBorder: "1px solid #1C1B1B",
  svgFill: "#1c1b1b",
  mapFill: "#fff",
  mapText: "#1c1b1b",
  teamBg: "#0d0d0d",
  movingBg: "#f4f4f4",
  boxShadow: "0px 5px 20px rgba(0 0 0 /12%)",
  serviceHeading: "#fff",
  serviceDescription: "#fff",
  contactBtn: "#e8ae1b",
  contactBtnBorder: "#e8ae1b",
  contactBtnSvg: "#1C1B1B",
  contactOverlay: "rgba(255,255,255,0.8)",
  bimBg: "#fff",
};

export const darkThemeFooter = {
  name: "darkFooter",
  text: "#6bfad2",
  body: "#1c1c1c",
};

export const lightThemeFooter = {
  body: "#f5f5f5",
  text: "#222222",
  name: "lightFooter",
};

export const darkTheme = {
  name: "dark",
  text: "#fff",
  body: "#1C1B1B",
  navbarLogo: "#fff",
  firstBox: "#292929",
  projectBoxHeadingBorder: "#e8ae1b",
  webkitStrokeText: "1px #ebebeb",
  servicesBg: "#1C1B1B !important",
  menuBorder: "1px solid white",
  svgFill: "#fff",
  mapFill: "#212121",
  mapText: "#fff",
  teamBg: "#393939",
  movingBg: "#292929",
  boxShadow: "0px 5px 20px rgba(0 0 0 /22%)",
  serviceHeading: "#1C1B1B",
  serviceDescription: "#1C1B1B",
  contactBtn: "#e8ae1b",
  contactBtnBorder: "#e8ae1b",
  contactBtnSvg: "#1C1B1B",
  contactOverlay: "rgba(28, 27, 27, 0.8)",
  bimBg: "#0d0d0d",
};
