import React, { useEffect } from "react";
import gsap from "gsap";

function Banner() {
  useEffect(() => {
    let threeDHeading = gsap.timeline({
      defaults: { ease: "Power3.easeInOut", duration: 1 },
    });
    threeDHeading.to(".three-d-heading", {
      clipPath: "polygon(100% 0, 0 0, 0 100%, 100% 100%)",
      opacity: 1,
      x: 0,
      duration: 1.2,
    });
    let threeDeeDesignDescription = gsap.timeline({
      defaults: { ease: "Power3.easeInOut", duration: 1 },
    });
    threeDeeDesignDescription.to(".threed-service-description-trigger", {
      clipPath: "polygon(100% 0, 0 0, 0 100%, 100% 100%)",
      opacity: 1,
      x: 0,
      duration: 1.2,
    });
  });
  return (
    <section className="three-d-design-bg services-design-bg">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 offset-lg-3">
            <div className="flex-center">
              <p className="service-description threed-service-description-trigger">
                We offer the best
              </p>
            </div>
            <h1 className="service-heading three-d-heading">
              3d Walkthroughs, <br />
              but a lot more.
            </h1>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Banner;
