import DownloadPdfButton from "../../Common/DownloadPdfButton";
import EstimationCategories from "../../Common/EstimationCategories";
import EstimationContact from "../../Common/EstimationContact";
import ServicesList from "../../Common/ServicesList";
import { drywallData, steelData } from "../../Data/Data";

const Main = () => {
  return (
    <section className="section-padding-normal " data-lenis-prevent>
      <div className="container">
        <div className="row">
          <div className="col-lg-9">
            <h1 className="estimate-heading">
              STEEL TAKEOFF & ESTIMATING SERVICES
            </h1>
            <p className="minor-description-3">
              At Dastkaar Studio, we specialize in construction cost consulting,
              providing top-tier structural steel estimating services tailored
              to various framing projects. As a prominent provider of structural
              steel estimates and steel take-offs, we cater to contractors,
              builders, fabricators, framers, steel erectors, and designers
              alike.
            </p>
            <h1 className="estimate-heading mt-50">
              Why We Standout in Steel Takeoff Services:
            </h1>
            <p className="minor-description-3">
              Dastkaar Studio manages a team of seasoned structural steel
              estimators, ready to leverage their years of experience to provide
              you with precise steel estimates swiftly and affordably. Take
              control of your costs by minimizing waste and streamlining the bid
              estimation process with our assistance. Say goodbye to concerns
              about material takeoffs as our team handles everything
              steel-related, from structural steel to decking, stairs, railing,
              grating, castings, and trusses.
              <br />
              Here are the standout features that set Dastkaar Studio apart:
              Quick Quotes: Send us your plans, and you'll receive a quote for
              estimates within just 30 minutes.
              <br />
              <span className="span-color">Fast Turnaround:</span> With our
              skilled estimators, we ensure swift delivery of estimates,
              typically within 2-3 days.
              <br />
              <span className="span-color">Accuracy:</span> Utilizing advanced
              steel estimating software, we meticulously measure all items
              manually, guaranteeing accuracy in our estimates.
              <br />
              <span className="span-color">Detailing:</span> Our steel estimates
              provide detailed breakdowns of material, labor, and equipment
              costs, facilitating precise budgeting.
              <br />
              <span className="span-color">Shop Drawings:</span> In addition to
              estimates, we offer dedicated shop drawing services to support you
              throughout the construction phase.
              <br />
              <span className="span-color">Dedicated Support:</span> Our team is
              available 24/7 via email or live chat on our website to address
              any queries or concerns you may have.
            </p>
            <div className="row mt-50">
              <div className="col-lg-6">
                <img
                  className="estimate-img"
                  src={process.env.PUBLIC_URL + "/images/steel.webp"}
                  alt="resdential"
                />
              </div>
              <div className="col-lg-6">
                <img
                  className="estimate-img"
                  src={process.env.PUBLIC_URL + "/images/steel-pdf.webp"}
                  alt="steel"
                />
              </div>
            </div>
            <div className="download-button">
              <DownloadPdfButton url="Metal.pdf" />
            </div>
            <h1 className="estimate-heading  mt-50">
              What We Offer in Steel Takeoff Services:
            </h1>
            <p className="minor-description-3">
              Our dedicated metal estimating department boasts expert metal
              framing estimators proficient in preparing estimates for a wide
              array of metals, including:
            </p>
            <div className="row">
              <ServicesList services={steelData} single={true} />
            </div>
            <p className="minor-description-3">
              With our expertise and attention to detail, we ensure accurate and
              comprehensive estimates that meet the unique requirements of each
              project. Partner with Dastkaar Studio for reliable structural
              steel estimating services that drive the success of your
              construction endeavors.
            </p>
          </div>
          <div className="col-lg-3">
            <EstimationCategories />
            <EstimationContact />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Main;
