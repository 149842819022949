import React from "react";

function Tiles({ tileName, tileDescription }) {
  return (
    <div className="col-lg-6">
      <div className="project-desc-mobile">
        <div className="project-desc">
          <div className="project-dots"></div>
          <p className="project-attributes-1">{tileName}</p>
        </div>
        <h6 className="project-attributes-2">{tileDescription}</h6>
      </div>
    </div>
  );
}
export default Tiles;
