import DownloadPdfButton from "../../Common/DownloadPdfButton";
import EstimationCategories from "../../Common/EstimationCategories";
import EstimationContact from "../../Common/EstimationContact";
import ServicesList from "../../Common/ServicesList";
import {
  concreteData1,
  concreteData2,
  concreteData3,
  drywallData,
  steelData,
} from "../../Data/Data";

const Main = () => {
  return (
    <section className="section-padding-normal " data-lenis-prevent>
      <div className="container">
        <div className="row">
          <div className="col-lg-9">
            <h1 className="estimate-heading">
              CONCRETE TAKEOFF & ESTIMATING SERVICES
            </h1>
            <p className="minor-description-3">
              Concrete is a fundamental material in construction, pivotal for
              building and civil works alike. Accurately estimating concrete
              quantity is a vital step in tendering for new projects. However,
              for intricate projects with varying concrete pavements and
              structural elements like foundations, walls, beams, and columns,
              precise estimation can be challenging.
            </p>
            <p className="minor-description-3">
              In such scenarios, outsourcing concrete estimating to
              professionals like Dastkaar Studio is a wise choice. Dastkaar
              Studio specializes in accurately estimating concrete quantities
              for diverse construction needs. Our experienced team
              comprehensively assesses project requirements, ensuring precision
              in every aspect of concrete estimation.
            </p>
            <p className="minor-description-3">
              By entrusting concrete estimating to Dastkaar Studio, you can
              focus on other crucial project management tasks, confident that
              your concrete quantity estimates are in capable hands. Trust
              Dastkaar Studio to deliver precise and reliable concrete
              estimates, empowering you to make informed decisions and
              competitively bid on construction projects.
            </p>
            <h1 className="estimate-heading mt-50">
              Why We Standout in Concrete Takeoff Services:
            </h1>
            <p className="minor-description-3">
              Concrete suppliers and contractors place their trust in Dastkaar
              Studio due to our reputation for{" "}
              <span className="soan-color">accuracy and swift delivery</span> of
              estimates. Our meticulous approach and use of advanced estimating
              software like Planswift and Bluebeam ensure that our estimates for
              all concrete-related works are precise and comprehensive.
            </p>
            <p className="minor-description-3">
              We understand that many construction estimating software options
              can be expensive and require significant time and resources to
              master. That's why Dastkaar Studio provides all the necessary
              tools, sparing you the expense and hassle of purchasing and
              training employees on costly software.
            </p>
            <p className="minor-description-3">
              As a builder, your focus should be on the construction project
              itself, not on acquiring and learning software. By relying on
              Dastkaar Studio for concrete estimating services, you can redirect
              your resources toward project execution and management, confident
              that your estimates are in capable hands.
            </p>
            <p className="minor-description-3">
              A <span className="span-color">bill of quantity (BOQ)</span> or
              concrete takeoff is essential before commencing concrete
              construction. It not only helps in estimating project budgets but
              also ensures smooth operation of the supply chain by indicating
              the required materials and maintaining adequate stock levels. With
              Dastkaar Studio's expertise in preparing{" "}
              <span className="soan-color">
                accurate and detailed material takeoffs
              </span>
              , you can streamline your construction processes and avoid
              interruptions in work progress. Trust Dastkaar Studio to provide
              you with reliable estimates and support for seamless project
              execution.
            </p>

            <div className="row mt-50">
              <div className="col-lg-6">
                <img
                  className="estimate-img"
                  src={process.env.PUBLIC_URL + "/images/concrete.webp"}
                  alt="resdential"
                />
              </div>
              <div className="col-lg-6">
                <img
                  className="estimate-img"
                  src={process.env.PUBLIC_URL + "/images/concrete-pdf.webp"}
                  alt="resdential"
                />
              </div>
            </div>
            <div className="download-button">
              <DownloadPdfButton url="Concrete.pdf" />
            </div>
            <h1 className="estimate-heading  mt-50">
              What We Offer in Concrete Takeoff Services:
            </h1>
            <p className="minor-description-3">
              In our concrete estimating services, we provide detailed
              spreadsheets containing comprehensive estimates for various
              construction items, ensuring clarity and accuracy in every aspect.
              These include:
            </p>
            <div className="row">
              <ServicesList services={concreteData1} />
            </div>
            <p className="minor-description-3">
              For both{" "}
              <span className="span-color">
                residential and commercial construction projects
              </span>
              , our concrete estimating services extend to cover a wide range of
              elements, including:
            </p>
            <div className="row">
              <ServicesList services={concreteData2} single={true} />
            </div>
            <p className="minor-description-3">
              Moreover, our overall estimates encompass all concrete-related
              items, ensuring a holistic understanding of project requirements.
              These include:
            </p>
            <div className="row">
              <ServicesList services={concreteData3} />
            </div>
            <p className="minor-description-3">
              By providing detailed estimates for each of these elements, we
              facilitate informed decision-making and efficient project
              planning, ensuring successful outcomes for our clients in every
              construction endeavor. Trust us at Dastkaar Studio to deliver
              precise and reliable concrete estimating services tailored to your
              project's needs.
            </p>
          </div>
          <div className="col-lg-3">
            <EstimationCategories />
            <EstimationContact />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Main;
