import React, { useEffect, useState } from "react";
import gsap from "gsap";
import Footer from "../../Common/Footer/Footer";
import Navbar from "../../Nav/Navbar";
import Sidedrawer from "../../Nav/Sidedrawer";
import Loader from "../../Common/Loader";
import BlogHeader from "./BlogHeader";
import HorizontalBlogCard from "./HorizontalBlogCard";
import VerticalBlogCard from "./VerticalBlogCard";
import { formatDate } from "../../utils/formatDate";
import { blogPage } from "../../utils/URLs";
import { useNavigate } from "react-router-dom";

const Blogs = ({ btoa, onToggle, show, handler, backdrop }) => {
  const navigate = useNavigate();
  useEffect(() => {
    document.title = "Blogs | Dastkaar";
  }, []);
  useEffect(() => {
    let tl = gsap.timeline({
      defaults: { ease: "Power3.easeInOut", duration: 1 },
    });
    tl.to(".contact-heading", {
      clipPath: "polygon(100% 0, 0 0, 0 100%, 100% 100%)",
      opacity: 1,
      x: 0,
      duration: 1.2,
    });
  });
  const [display, setDisplay] = useState(true);
  const [blogCategories, setBlogCategories] = useState({
    FeaturedArticles: [],
    PriortyArticles: [],
    LatestArticles: [],
    DesignDrafting: [],
    ModellingRendering: [],
    EstimationCostManagement: [],
  });

  console.log("blogCategories===>", blogCategories);

  const fetchBlogsData = async () => {
    try {
      const res = await fetch(`${process.env.REACT_APP_BASE_URL}/blog/`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Basic " + btoa,
        },
      });
      const response = await res.json();
      orderDataInCatgeories(response);
      setDisplay(false);
    } catch (error) {
      console.error(error);
      setDisplay(true);
    }
  };

  useEffect(() => {
    fetchBlogsData();
  }, []);

  const orderDataInCatgeories = (response) => {
    const categories = { ...blogCategories };
    response?.forEach((blog) => {
      const key = blog.category.replace(/\/|\s/g, "") || "latestArticles";
      categories[key] = categories[key] ? [...categories[key], blog] : [blog];
    });
    setBlogCategories(categories);
  };

  const priortyImage =
    blogCategories?.PriortyArticles[0]?.cover_image &&
    JSON.parse(blogCategories?.PriortyArticles[0]?.cover_image);

  return (
    <div data-lenis-prevent>
      {display ? (
        <Loader />
      ) : (
        <>
          <Navbar onToggle={onToggle} handler={handler} />
          <Sidedrawer onToggle={onToggle} show={show} handler={handler} />
          {backdrop}
          <main>
            <section className="section-padding-top">
              <div className="container" style={{ paddingTop: "8vh" }}>
                <div className="row flex-center">
                  <div className="col-lg-12">
                    <h1
                      className="blog-heading-"
                      style={{
                        textAlign: "center",
                        marginBottom: "50px",
                        fontSize: "50px",
                        fontWeight: "bold",
                      }}
                    >
                      Blogs<span className="span-color">!</span>
                    </h1>
                    <div className="row mb-50">
                      <div className="col-lg-6">
                        <div
                          className="priorty-blog-card"
                          onClick={() =>
                            navigate(
                              `/${blogPage}/${blogCategories?.PriortyArticles[0]?.slug}`
                            )
                          }
                        >
                          <div className="blog-priorty-image">
                            <img
                              className="blog"
                              src={priortyImage[0]?.url}
                              alt="blog"
                            />
                          </div>
                          <div className="blog-priorty-body">
                            <h3 className="blog-priorty-title">
                              {blogCategories?.PriortyArticles[0]?.title
                                ?.split(" ")
                                ?.slice(0, 16)
                                ?.join(" ")}
                            </h3>
                            <div className="blog-post-card-footer">
                              <p className="priorty-blog-post-card-author">
                                <a
                                  href={
                                    blogCategories?.PriortyArticles[0]?.redirect
                                  }
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  {blogCategories?.PriortyArticles[0]?.author}
                                </a>
                              </p>
                              <p className="priorty-blog-post-card-date">
                                {formatDate(
                                  blogCategories?.PriortyArticles[0]?.created_at
                                )}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        {blogCategories?.FeaturedArticles.length > 0 && (
                          <div>
                            <BlogHeader name="Featured Articles" />
                            <ul className="blog-post-featured-list">
                              {blogCategories?.FeaturedArticles?.slice(
                                0,
                                3
                              )?.map((blog) => (
                                <HorizontalBlogCard blogData={blog} />
                              ))}
                            </ul>
                          </div>
                        )}
                      </div>
                    </div>

                    {blogCategories?.LatestArticles.length > 0 && (
                      <div className="col-lg-12 mb-50 mt-50">
                        <BlogHeader name="Latest Articles" />
                        <div className="blogs-wrapper">
                          <ul className="blog-post-list  -two-col ">
                            {blogCategories?.LatestArticles.map((blog) => (
                              <HorizontalBlogCard blogData={blog} />
                            ))}
                          </ul>
                        </div>
                      </div>
                    )}
                    {blogCategories?.DesignDrafting.length > 0 && (
                      <div className="col-lg-12 mb-50">
                        <BlogHeader name="Architectural Design & Drafting" />
                        <div className="blogs-wrapper">
                          <ul className="blog-post-list">
                            {blogCategories?.DesignDrafting?.map((blog) => (
                              <VerticalBlogCard blogData={blog} />
                            ))}
                          </ul>
                        </div>
                      </div>
                    )}

                    {blogCategories?.ModellingRendering.length > 0 && (
                      <div className="col-lg-12 mb-50">
                        <BlogHeader name="3D Modelling & Rendering" />
                        <div className="blogs-wrapper">
                          <ul className="blog-post-list">
                            {blogCategories?.ModellingRendering?.map((blog) => (
                              <VerticalBlogCard blogData={blog} />
                            ))}
                          </ul>
                        </div>
                      </div>
                    )}
                    {blogCategories?.EstimationCostManagement.length > 0 && (
                      <div className="col-lg-12 mb-50">
                        <BlogHeader name="Estimation & Cost Management" />
                        <div className="blogs-wrapper">
                          <ul className="blog-post-list">
                            {blogCategories?.EstimationCostManagement?.map(
                              (blog) => (
                                <VerticalBlogCard blogData={blog} />
                              )
                            )}
                          </ul>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </section>
          </main>
          <Footer />
        </>
      )}
    </div>
  );
};

export default Blogs;
