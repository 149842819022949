import React, { useEffect, useLayoutEffect } from "react";
import Footer from "../../Common/Footer/Footer";
import Navbar from "../../Nav/Navbar";
import HeroSection from "./HeroSection";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import Sidedrawer from "../../Nav/Sidedrawer";
import Team from "../Home/Team";
import Location from "./Location";
function Main({ onToggle, show, handler, backdrop, teamData, gsapLoading }) {
  //
  //
  gsap.registerPlugin(ScrollTrigger);

  useLayoutEffect(() => {
    // let aboutHeaderTrigger = gsap.timeline({
    //   scrollTrigger: {
    //     trigger: ".about-trigger-item",
    //     start: "bottom center",
    //     end: "bottom top",
    //     scrub: 1,
    //   },
    // });
    // aboutHeaderTrigger
    //   .to(".header", {
    //     padding: "20px 0px",
    //     duration: 1,
    //   })
    //   .to(".navbar-main", {
    //     border: "1px solid #c9981a",
    //   });

    //AboutPage
    let aboutPage_heading = gsap.timeline({
      defaults: { ease: "Power3.easeInOut", duration: 1 },
    });
    aboutPage_heading.to(".about-heading", {
      clipPath: "polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)",
      duration: 2.2,
    });

    //AboutPageParagraph
    let aboutPage_Paragraph = gsap.timeline({
      defaults: { ease: "Power3.easeInOut", duration: 1 },
    });
    aboutPage_Paragraph.to(".about-para", {
      clipPath: "polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)",
      duration: 2.2,
    });

    //AboutTeam
    let aboutTeamTrigger = gsap.timeline({
      scrollTrigger: {
        trigger: ".team-bg",
        toggleActions: "play resume restart reverse",
        scrub: 1,
        start: "0px bottom",
        pinSpacing: false,
      },
    });
    aboutTeamTrigger
      .to(".dee-image", {
        rotation: 360,
        duration: 3,
      })
      .to(".dee-image-2", {
        rotation: 360,
        duration: 3,
      });

    // Refresh
    ScrollTrigger.refresh();
  });
  return (
    <>
      <Navbar onToggle={onToggle} handler={handler} />
      <Sidedrawer onToggle={onToggle} show={show} handler={handler} />
      {backdrop}
      <main>
        <HeroSection />
        {/* <Location /> */}
        {/* <Team teamData={teamData} />   */}
      </main>
      <Footer />
    </>
  );
}

export default Main;
