import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import gsap from "gsap";
import Circles from "../../Common/Circles";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { projectsData } from "../../Data/Data";
import { projectDetailPage, projectsPage } from "../../utils/URLs";

function ResidentialProjects({ projectsData }) {
  const [residentialData, setResidentialData] = useState();

  const data = projectsData;

  let res;

  useEffect(() => {
    res = data?.filter((i) => i?.category === "Residential");
    setResidentialData(res);
  }, [projectsData]);

  return (
    <div className="residential-projects">
      <div className="container-fluid" style={{ overflowX: "hidden" }}>
        <h6 className="projects-heading-main residential-heading">
          Residential Projects
        </h6>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-lg-1"></div>
          <div className="col-lg-10 remove-padding-desktop">
            <div className="row">
              {residentialData?.map((i, index) => (
                <div className="col-lg-6" key={index} data-lenis-prevent>
                  <Link
                    to={`/${projectDetailPage}/${i?.slug}`}
                    state={{ id: i?.id }}
                  >
                    <div className="projects-box residential-projects-box">
                      <div className="projects-img-box">
                        <div className="project-img-container">
                          <img
                            src={
                              i?.images[0]?.image_url
                                ? i?.images[0]?.image_url
                                : i?.images[0]?.image
                            }
                            alt="alt"
                            className="project-img"
                            loading="lazy"
                          />
                        </div>
                        <Circles
                          firstColor={i?.primary}
                          secondColor={i?.secondary1}
                          thirdColor={i?.secondary2}
                          needAnimtation={true}
                        />
                      </div>
                      <h6 className="project-name">
                        {i?.name}
                        <div className="project-name-span special-span"></div>
                      </h6>
                      <p>{i?.category}</p>
                      <div className="project-flex">
                        <p className="project-info">{i?.description}</p>

                        <div className="project-link">
                          <img
                            src={process.env.PUBLIC_URL + "/icons/link.svg"}
                            alt="alt"
                          />
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              ))}
            </div>
          </div>
          <div className="col-lg-1"></div>
        </div>
      </div>
    </div>
  );
}

export default ResidentialProjects;
